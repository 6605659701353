import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../..';
import { Cancellation } from './Cancellation';
import { useFormik } from 'formik';
import { setFeesError, setTaxesError } from '../../Services/Formik/Formik';
import { readRecord, updateRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { Taxes } from './Taxes';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const Fees = () => {
    const { id } = useParams();
    const keyVal: any = id;
    const navigate = useNavigate();
    const [remainderID, setRemainderID] = useState();
    const [indexValue, setIndexValue] = useState(0);
    const [selectVal, setSelectVal] = useState('percent');
    const [tabText, setTabText] = useState('Cancellation');
    const [isSave, setIsSave] = useState(false);
    const [count, setCount] = useState(0);
    const [
        { token },
        {
            cancellation_range,
            cancellation_value,
            gst,
            internet_charge,
            charge_type,
            id: appConfigId,
        },
    ] = useAppSelector(({ signin, appConfig }: any) => [
        signin['signinValue'],
        appConfig['appConfig'],
    ]);
    const switchTabs = ['Cancellation', 'Taxes'];

    useEffect(() => {
        if (gst) {
            setRemainderID(appConfigId);

            setFieldValue('data21', Number(cancellation_range[0]));
            setFieldValue('data31', Number(cancellation_range[1]));
            setFieldValue('data12', Number(cancellation_range[0]));
            setFieldValue('data22', Number(cancellation_range[1]));
            setFieldValue('data32', Number(cancellation_range[2]));
            setFieldValue('percent1', Number(cancellation_value[0]));
            setFieldValue('percent2', Number(cancellation_value[1]));
            setFieldValue('percent3', Number(cancellation_value[2]));
            setFieldValue('gst', Number(gst));
            setFieldValue('internet_charge', Number(internet_charge));
            setFieldValue('charge_type', charge_type);
            setSelectVal(charge_type);
        }
    }, [gst]);

    const submitRequestForFees = async () => {
        console.log('save btn');

        // setIsSave(true);
        if (isSave) {
            if (tabText === 'Cancellation') {
                if (values.percent3.length < values.percent2.length) {
                    return formik.setFieldError(
                        'percent3',
                        'Enter greater value'
                    );
                } else if (values.percent2.length < values.percent1.length) {
                    return formik.setFieldError(
                        'percent2',
                        'Enter greater value'
                    );
                }
            }
            const {
                data12,
                data22,
                data32,
                percent1,
                percent2,
                percent3,
                internet_charge,
                charge_type,
                gst,
                ...rest
            } = values;
            const body = {
                ...(tabText === 'Cancellation' && {
                    id: Number(remainderID),
                    cancellation_range: [data12, data22, data32],
                    cancellation_value: [percent1, percent2, percent3],
                }),
                ...(tabText === 'Taxes' && {
                    id: Number(remainderID),
                    gst: gst,
                    internet_charge: internet_charge,
                    charge_type: charge_type,
                }),
            };
            console.log(body, 'body');
            const response = await updateRecord(body, 'admin/adminconfigs');
            console.log(response, 'response');
            if (response.status) {
                toast.success(response.message);
            } else if (
                response.message ===
                camelCaseConvertForErrorMessage('Please sign in to continue')
            ) {
                navigate('/');
                toast.error(response.message);
            } else toast.error(response.message);
        }
        setIsSave(false);
    };

    const input = {
        data11: Number(0),
        data12: '',
        data21: '',
        data22: '',
        data31: '',
        data32: '',
        percent1: '',
        percent2: '',
        percent3: '',
    };

    const inputForTax = {
        gst: '',
        internet_charge: '',
        charge_type: 'percent',
        id: '',
    };

    const TypeValues: any = {
        cancellation: {
            input: input,
            validation: setFeesError,
        },
        taxes: {
            input: inputForTax,
            validation: () => setTaxesError(selectVal),
        },
    };

    const formik = useFormik({
        initialValues: TypeValues[keyVal]['input'],
        validationSchema: TypeValues[keyVal]['validation'],
        onSubmit: () => {
            submitRequestForFees();
        },
    });

    const {
        values,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        setFieldValue,
        errors,
        resetForm,
    } = formik;

    const selectedField = (index: any, tab: string) => {
        navigate(`/dashboard/fees/${tab.toLowerCase()}`);
        setIndexValue(index);
        setTabText(tab);
    };

    let style =
        'cursor-pointer bg-[#267DF8] rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6';
    let [btnStyle, setBtnStyle] = useState(style);

    useEffect(() => {
        if (isSave) {
            setBtnStyle(
                'cursor-pointer bg-[#267DF8] rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6'
            );
        } else {
            setBtnStyle(
                'cursor-pointer bg-gray-300 rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6'
            );
        }
    }, [isSave]);

    useEffect(() => {
        if (
            count > 1 &&
            (values.data11 ||
                values.data12 ||
                values.data21 ||
                values.data22 ||
                values.data31 ||
                values.data32 ||
                values.gst ||
                values.internet_charge ||
                values.percent1 ||
                values.percent2 ||
                values.percent3)
        ) {
            setIsSave(true);
        }
        setCount(count + 1);
    }, [
        values.data11,
        values.data12,
        values.data21,
        values.data22,
        values.data31,
        values.data32,
        values.gst,
        values.internet_charge,
        values.percent1,
        values.percent2,
        values.percent3,
    ]);

    return (
        <form className="w-11/12 mx-auto mt-8" onSubmit={handleSubmit}>
            <h1 className="text-[18px] font-[600]">Fees</h1>
            <div className="mt-10">
                <div>
                    <div className="float-right -mt-4">
                        <button
                            type="submit"
                            className={btnStyle}
                            // disabled={isSave}
                        >
                            Save
                        </button>
                    </div>
                    <div className="flex gap-6">
                        {switchTabs.map((tab, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => selectedField(index, tab)}
                                >
                                    <p
                                        className={`${
                                            indexValue === index
                                                ? 'text-[#085BEB] font-[500] border-b-[3px] border-[#085BEB]'
                                                : 'font-[400] text-black'
                                        } text-[16px] cursor-pointer transition-all ease-in-out duration-300 h-8`}
                                    >
                                        {tab}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <hr className="border-b-2 -mt-0.5"></hr>
            </div>
            {indexValue === 0 ? (
                <Cancellation formik={formik} />
            ) : (
                <Taxes
                    formik={formik}
                    setSelectVal={setSelectVal}
                    selectVal={selectVal}
                />
            )}
        </form>
    );
};
