import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { remTypes, remState } from '../Types/reminderResponseType';

const initialState: remState = {
    ReminderData: {
        reminderResponse: [],
    },
};

const reminderResponseSlice = createSlice({
    name: 'rem',
    initialState,
    reducers: {
        setReminderResponse: (state: remState, action: PayloadAction<any>) => {
            state.ReminderData = {
                ...state.ReminderData,
                ...action.payload,
            };
        },
    },
});

export const { setReminderResponse } = reminderResponseSlice.actions;
export default reminderResponseSlice.reducer;
