import {useState} from 'react'

export const Taxes = ({ formik, setSelectVal, selectVal }: any) => {
    const [taxVal, setTaxVal] = useState(false);
    // const [selectVal, setSelectVal] = useState('percent');

    const taxCalculation = (text: string) => {
        // console.log(formik.values.charge_type !== selectVal, "val");
        // if (formik.values.charge_type !== selectVal) {
        //     formik.setFieldValue('internet_charge', '');
        // }
        setSelectVal(text);
        // setTaxVal(data);
        formik.setFieldValue('internet_charge', '');
        console.log(text.toLowerCase(),"text");
        formik.setFieldValue('charge_type', text.toLowerCase());
    };

    console.log(
        typeof formik.values.charge_type,
    );

    return (
        <div className="space-y-4 pt-10">
            <div className="flex flex-col space-y-4">
                <h1 className="text-[#505050] text-[16px] font-[500]">
                    Internet Charges:
                </h1>
                <div className="flex gap-4 ml-2 mt-4">
                    <RadioType
                        name="charge_type"
                        type={'radio'}
                        label={'Percentage'}
                        checked={selectVal === 'percent'}   
                        onChange={() => taxCalculation('percent')}
                    />
                    <RadioType
                        name="charge_type"
                        type={'radio'}
                        label={'Amount'}
                        checked={selectVal === 'amount'}
                        onChange={() => taxCalculation('amount')}
                    />
                </div>
                <InputType
                    name={'internet_charge'}
                    type={'number'}
                    className={'ml-1'}
                    focus={true}
                    value={formik.values.internet_charge}
                    onBlur={formik.handleBlur}
                    error={formik.errors.internet_charge}
                    onChange={formik.handleChange}
                    isTouched={formik.touched.internet_charge}
                    checkData={formik.values.charge_type}
                />
            </div>
            <div className="flex flex-col space-y-2">
                <h1 className="text-[#505050] text-[16px] font-[500]">GST:</h1>
                <InputType
                    name={'gst'}
                    type={'number'}
                    className={'ml-1'}
                    value={formik.values.gst}
                    onBlur={formik.handleBlur}
                    error={formik.errors.gst}
                    onChange={formik.handleChange}
                    isTouched={formik.touched.gst}
                />
            </div>
        </div>
    );
};

const RadioType = ({ label,onChange,...rest }: any) => {
    return (
        <div className="flex gap-2 cursor-pointer">
            <input
                onChange={onChange}
                {...rest}
            />
            <label className="text-[14px] cursor-pointer">{label}</label>
        </div>
    );
}

const InputType = ({name,className,focus,isTouched,error,checkData,...rest}:any) => {
    return (
        <div className="flex items-end gap-2">
            <div className="relative flex items-center justify-end w-3/12 h-[42px]">
                {name === 'gst' && (
                    <p className="text-[#505050] text-[14px] font-[500] px-2 absolute top-1/3">
                        %
                    </p>
                )}
                {name === 'internet_charge' && checkData === 'percent' ? (
                    <p className="text-[#505050] text-[14px] font-[500] px-2 absolute top-1/3">
                        %
                    </p>
                ) : (
                    name === 'internet_charge' &&
                    checkData === 'amount' && (
                        <div className="border-[1px] border-[#E2E2E2] p-2 rounded-l-[6px] flex items-center justify-end">
                            <p className="font-[500] text-[15px] bg-white">
                                INR
                            </p>
                        </div>
                    )
                )}
                <input
                    name={name}
                    type={'number'}
                    autoFocus={focus}
                    className={`${className} ${
                        name === 'internet_charge' && checkData === 'amount'
                            ? 'rounded-r-[6px]'
                            : 'rounded-[6px]'
                    } w-full numberClass px-2 h-[42px] border-[1px] border-[#E2E2E2]`}
                    {...rest}
                />
            </div>
            {isTouched && error && (
                <span className="text-red-500 text-[14px] font-[500]">
                    {error}
                </span>
            )}
        </div>
    );
}
