import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../Elements/Input/Input';
import { postRecord } from '../../../Services/backend/apiCalls';
import { useFormik } from "formik";
import { CreateValidationEmailSchema } from '../../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import { PulseLoader } from "react-spinners";
import curveLine from "../../../assets/Images/curveLine.png";
import Login_Logo from "../../../assets/Images/Login_Logo.svg";
import { URLConfig } from '../../../config';

export const EmailVerification = () => {
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const [respondPage, setRespondPage] = useState(false)

    // const BackToSignIN = () => {
    //   navigate("/")
    // }

    const handleSubmit = async() => {
      setLoading(true);
      const emailsendRequest = {...formik.values};
      const response = await postRecord(emailsendRequest, 'forgotpassword');
      if (response.status) {
        formik.setFieldValue('email', '')
        setLoading(false)
        toast.success(response.message)
        setRespondPage(true)
      } else {
        formik.resetForm();
        toast.error(response.message)
        setLoading(false)
      }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            redirect_url: URLConfig[document.location.hostname],
        },
        validationSchema: CreateValidationEmailSchema,
        onSubmit: () => {
            handleSubmit();
        },
    });

  return (
      <div className="bg-[#FBFCFC] border w-7/12 lg:w-11/12">
          <img src={curveLine} alt="" className="absolute top-0 right-0" />
          <form
              className="flex flex-col justify-center items-start w-9/12 lg:w-[292px] mx-auto space-y-3 h-3/4"
              onSubmit={formik.handleSubmit}
          >
              <div className="mb-0">
                  <img
                      src={Login_Logo}
                      alt="Login_Logo"
                      className="w-[190px] mb-5"
                  />
                  {/* <div>
            <h1 className="text-[22px] font-[400]">Welcome <span className="font-[600]">Trisekt</span></h1>
            <p className="text-[13px] font-[300]">Welcome back! Please enter your details.</p>
          </div> */}
              </div>
              <Input
                  design={'w-full pt-10'}
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.email}
                  isTouched={formik.touched.email}
                  impo="impo"
              />
              <div className="flex justify-between w-full gap-4 pt-16">
                  <button
                      className="bg-white border-[#EFEFEF] border-[1px] w-full p-3 text-[12px] font-[400] text-black mt-6 rounded-md"
                      onClick={() => navigate('/')}
                  >
                      Back
                  </button>
                  {loading ? (
                      <button
                          className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-3 text-[12px] font-[400] text-white mt-6 rounded-md opacity-30"
                          disabled={true}
                      >
                          Send
                      </button>
                  ) : (
                      <button
                          className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-3 text-[12px] font-[400] text-white mt-6 rounded-md"
                          type="submit"
                      >
                          Send
                      </button>
                  )}
              </div>
          </form>
      </div>
  );
}
