import { configureStore } from '@reduxjs/toolkit';
import zipcodeReducer from './Reducers/zipcodeReducers';
import signinReducer from './Reducers/signinUserReducers';
import directrequestReducer from './Reducers/directRequestReducers';
import ModalSlice from './Reducers/popupReducers';
import viewCourseDetails from './Reducers/viewCourseReducers';
import reminderReducer from './Reducers/reminderReducer';
import advertisementReducers from './Reducers/advertisementReducers';
import couponsReducers from './Reducers/couponsReducers';
import reminderResponseReducer from './Reducers/reminderResponseReducer';
import adminConfigReducer from "./Reducers/adminReducers";

export const store = configureStore({
    reducer: {
        zipcode: zipcodeReducer,
        signin: signinReducer,
        directRequest: directrequestReducer,
        modal: ModalSlice,
        view: viewCourseDetails,
        reminder: reminderReducer,
        getAd: advertisementReducers,
        getCoupon: couponsReducers,
        rem: reminderResponseReducer,
        appConfig:adminConfigReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
