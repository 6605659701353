import React from 'react';

export const UserSelection = ({
    setSelectedUser,
    selectedUser,
    tabChange,
}: any) => {
    const users = [
        {
            displayText: 'Trainer',
            value: 'Trainer',
        },
        {
            displayText: 'Trainee',
            value: 'Trainee',
        },
    ];

    const selected =
        'bg-[#F4F7FF] text-[#267DF8] border-[1px] border-[#267DF8] px-4 py-2 w-[85px] text-center cursor-pointer';
    const unSelected =
        'border-[1px] border-[#EBEBED] px-4 py-2 w-[85px] text-center cursor-pointer';

    return (
        <div className="flex items-center">
            {users.map((user: any, index: number) => {
                return (
                    <p
                        key={index}
                        className={`${
                            selectedUser === user.value ? selected : unSelected
                        } ${index === 0 && 'rounded-l-md'} ${
                            index === users.length - 1 && 'rounded-r-md'
                        }`}
                        role={'button'}
                        onClick={() => {
                            setSelectedUser(user.value);
                            tabChange(user.value);
                        }}
                    >
                        {user.displayText}
                    </p>
                );
            })}
        </div>
    );
};
