import illustration from "../../assets/Images/illustration.svg";
import upperCurve from "../../assets/Images/upperCurve.svg";
import upperCircle from "../../assets/Images/upperCircle.svg";
import lowerCurve from "../../assets/Images/lowerCurve.svg";
import { useNavigate } from "react-router-dom";

export const InfoPage = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gradient-to-b from-[#0074FC] to-[#00E0B8] flex flex-col items-center justify-center w-5/12 relative">
      <img src={upperCircle} className="absolute top-3 xl:top-6" alt="circleImage" />
      <img src={upperCurve} alt="circleImage" className="absolute top-0" />
      <div className="flex flex-col items-center justify-around">
        <img src={illustration} alt="illustration" className="w-10/12 cursor-pointer" onClick={()=>navigate('/login')} />
        <div className="mt-4 flex flex-col items-center justify-around w-11/12 xl:w-10/12">
          <h1 className="text-[22px] text-white">The Best Courses</h1>
          <p className="text-center text-white text-[14px]">
            Lorem Ipsum is simply dummy text of the printing & typesetting Lorem
            Ipsum
          </p>
        </div>
      </div>
      <img src={lowerCurve} alt="circleImage" className="absolute bottom-0" />
    </div>
  );
};
