import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import fb from "../../../assets/Images/fb.svg";
import google from "../../../assets/Images/google.svg";
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider,linkWithPopup } from 'firebase/auth';
import { authentication } from '../../../Firebase/firebase';
import { useAppDispatch } from '../../..';
import {setSignValue} from "../../../Redux/Reducers/signinUserReducers";
import { toast } from 'react-hot-toast';
import { storeLoggedUserDetails } from '../Signin/Signin';
import { camelCaseConvertForErrorMessage } from '../../../Services/commonFunctions';

export const Providers = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const storeUserData = ({token,email,uid,role}:any) => {
    dispatch(setSignValue({ token, email, uid, role }));
  }


  const loginFunction = async(type:any) => {
    let provider;
    if(type === "google"){
        provider = new GoogleAuthProvider();
    } else {
        provider = new FacebookAuthProvider();
    }
    try{
        let result = await signInWithPopup(authentication, provider)
        let credential:any;
        if(type === "google"){
            credential = GoogleAuthProvider.credentialFromResult(result);
        } else{
            credential = FacebookAuthProvider.credentialFromResult(result);
            console.log(credential.accessToken)
        }
        storeLoggedUserDetails(result, navigate, storeUserData);
    } catch(error:any){
        const errorMessage = error.message;
        const code = error.code;
        if (type === "fb") {
            const credential = FacebookAuthProvider.credentialFromError(error);
            console.log({ errorMessage,credential, code })
            let errorText: string = error.code.split("/")[1].replace(/-/g," ");
            // console.log(errorText);
            if (
                code ===
                camelCaseConvertForErrorMessage(
                    'auth/account-exists-with-different-credential'
                )
            ) {
                try {
                    const linkedResult = await linkWithPopup(
                        authentication.currentUser,
                        provider
                    );
                    storeLoggedUserDetails(
                        linkedResult,
                        navigate,
                        storeUserData
                    );
                } catch (error) {
                    toast.error(
                        camelCaseConvertForErrorMessage(
                            'Enter email and password'
                        )
                    );
                }
            }
        }
    }
  }

  return (
    <div className='flex items-center gap-x-6 justify-center py-3'>
        <img alt='googleIcon' src={google} onClick={()=>loginFunction("google")} className="cursor-pointer bg-[#EFEFEF] rounded-lg stroke-[#EFEFEF]" />
        <img alt='fbIcon' src={fb} onClick={()=>loginFunction("fb")} className="cursor-pointer bg-[#EFEFEF] rounded-lg stroke-[#EFEFEF]" />
    </div>
  )
}