import { useEffect, useState } from 'react';

export const UpcomingTraining = ({ formik }: any) => {
    const ReminderJSON = [
        {
            title: 'Reminder for the upcoming training',
            name: 'data1',
        },
        {
            title: 'Reminder for the upcoming training',
            name: 'data2',
        },
        {
            title: 'Reminder for the upcoming training',
            name: 'data3',
        },
    ];

    return (
        <div className="space-y-10">
            {ReminderJSON.map((rem, idx) => {
                return (
                    <div
                        key={idx}
                        className="flex justify-between items-center w-4/5 xl:w-3/5"
                    >
                        <p className="text-[15px] font-[500]">{rem.title}</p>
                        <div className="relative flex items-center justify-end">
                            <p className="text-[#505050] text-[14px] font-[500] border-l-2 px-2 absolute">
                                Days
                            </p>
                            <div className="flex">
                                <input
                                    name={rem.name}
                                    type="number"
                                    value={formik.values[rem.name]}
                                    maxLength={3}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    className="numberClass px-2 border-[#E2E2E2] border-[1px] rounded-[6px] w-[173px] h-[42px]"
                                />
                                {formik.errors[rem.name] &&
                                    formik.touched[rem.name] && (
                                        <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-22px]">
                                            {formik.errors[rem.name]}
                                        </p>
                                    )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
