import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    value: localStorage.getItem('UpcomingTraining') || 'UpcomingTraining',
};

const reminderSlice = createSlice({
    name: 'reminder',
    initialState,
    reducers: {
        showReminderTabs: (state: any, action: PayloadAction<any>) => {
            localStorage.setItem('UpcomingTraining', action.payload);
            state.value = action.payload;
        },
    },
});

export const { showReminderTabs } = reminderSlice.actions;
export default reminderSlice.reducer;
