// export const URLConfig:any = {
//     'trisekt-deeto.web.app': "https://trisekt-deeto.web.app/",
//     'localhost': "http://localhost:3000/",
//     'trisekt---uat.web.app':"https://trisekt---uat.web.app/"
// }

export const URLConfig:any = {
    'localhost': `http://localhost:3000/`,
    'admin.trisekt.com': "https://admin.trisekt.com/",
    'admin-production-site.web.app': "https://admin-production-site.web.app/"
}