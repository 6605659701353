import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    showAdModal: false,
    showCouponsModal: false,
    showProfileModal: false
}

const ModalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showAdvertisementModal: (state: any, action: PayloadAction<any>) => {
            state.showAdModal = action.payload;
        },
        showAllCouponsModal: (state: any, action: PayloadAction<any>) => {
            state.showCouponsModal = action.payload;
        },
        showProfileDetailModal: (state: any, action: PayloadAction<any>) => {
            state.showProfileModal = action.payload;
        }
    }
});

export const { showAdvertisementModal, showAllCouponsModal, showProfileDetailModal } = ModalSlice.actions;
export default ModalSlice.reducer;