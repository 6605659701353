import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { couponTypes, couponState } from '../Types/couponsType';

const initialState: couponState = {
    CouponsData: {
        coupons: [],
    },
};

const couponSlice = createSlice({
    name: 'getCoupon',
    initialState,
    reducers: {
        setCoupon: (state: couponState, action: PayloadAction<any>) => {
            state.CouponsData = {
                ...state.CouponsData,
                ...action.payload,
            };
        },
    },
});

export const { setCoupon } = couponSlice.actions;
export default couponSlice.reducer;
