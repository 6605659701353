import axios from "axios";
import axiosClient from "./axiosClient";
import qs from "qs";
import { NavigateToSignInWithRouteParams } from "../commonFunctions";

// // Login API Calls

// export async function signup(body :any) {
//     return axiosClient.post("/signup", body).then(res=>Promise.resolve(res.data))
// }

// export async function resendEmailLink(body :any) {
//     return axiosClient.post("/resendmail", body).then(res=>Promise.resolve(res.data))
// }

// export async function signin(bodyData :any) {
//     return axiosClient.post("/signin", bodyData).then(res=>Promise.resolve(res.data))
// }

// export async function forgotpassword(emailsendRequest : any) {
//     return axiosClient.post("/forgotpassword", emailsendRequest).then(res=>Promise.resolve(res.data))
// }

// export async function updatepassword(body: any, data: any) {
//     const headers = {
//         "token": data
//     }
//     return axiosClient.put("/updatepassword",body,{headers}).then(res=>Promise.resolve(res.data))
// }

// export async function roleaction(body: any, data: any) {
//     const headers = {
//         "token": data
//     }
//     return axiosClient.post("/roleaction", body,{headers}).then(res=>Promise.resolve(res.data))
// }

// // Profile API Calls

// export async function getprofile(token: any, uid: any) {
//     const headers = {
//         "token": token
//     }
//     return axiosClient.get(`/profiles/?uid=${uid}`,{headers}).then(res=>Promise.resolve(res.data))
// }

// export async function createprofile(body: any, data: any) {
//     const headers = {
//         "token": data
//     }
//     return axiosClient.post("/profiles", body,{headers}).then(res=>Promise.resolve(res.data))
// }

// export async function updateprofile(token: any, body: any) {
//     const headers = {
//         "token": token
//     }
//     return axiosClient.put("/profiles", body, {headers}).then(res=>Promise.resolve(res.data))
// }   

// export async function placesuggestion(input: any,city:string) {
//     const params = {
//         input,
//         city
//     }
//     return axiosClient.get("/placesuggestion", {params}).then(res=>Promise.resolve(res.data));
// }

// export async function getaddress(pinNum: any) {
//     const params = {
//         "pincode": pinNum
//     }
//     return axiosClient.get("/getaddress", {params}).then(res=>Promise.resolve(res.data))
// }

// // dashboard API Calls

// export async function getDashboardData (token:any){
//     const headers = {
//         token: token
//     }
//     return axiosClient.get("/dashboard", { headers }).then(res => Promise.resolve(res.data)).catch(err=>Promise.reject(err))
// }

// export async function biddingSuggestions (token:any, alreadyfetcheditems:any){
//     const headers = {
//         token: token
//     }
//     const body = {
//         alreadyfetcheditems: alreadyfetcheditems
//     }
//     return axiosClient.post("/biddingsuggestions", body, { headers }).then(res => Promise.resolve(res.data));
// }

// export async function getTrainingStatistics(token:any){
//     const headers = {
//         token
//     }
//     return axiosClient.get("/trainingstatistics", { headers }).then(res => Promise.resolve(res.data));
// }

// export const getDirectRequests = async (token :any,status:string) => {
//     const headers = { 
//         "token": token 
//     };
//     const params={
//         status
//     }
//     return axiosClient.get("/directrequests", { headers,params }).then(res => Promise.resolve(res.data));
// }

// export const updateDirectRequests = async (token:any, body:any) => {
//     return axiosClient.put("/directrequests", body, { headers: { token } }).then(res => Promise.resolve(res.data));
// }

// export const getTrainings = async (page=0,token:any,query:string) => {
//     const headers = { token };
//     const params = { status: query, page };
//     return axiosClient.get("/trainings", { headers,params }).then(res => Promise.resolve(res.data));
// }

// export const getBiddings = async (page=0,token:any,query:string) => {
//     const headers = { token };
//     const params = { status: query, page };
//     return axiosClient.get("/biddings", { headers, params }).then(res => Promise.resolve(res.data));
// }

// export const getDirectRequestSuggestions = async (token:any, alreadyfetcheditems:any) => {
//     const headers = { token };
//     const body = {
//         alreadyfetcheditems
//     }
//     return await axiosClient.post("/directrequestsuggestions",body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const getCheckoutDetails = async (token: any, id: number, checkoutOrigin: string) => {
//     return await axiosClient.get("/checkout", { headers:{token},params:{id,checkoutOrigin} }).then(res => Promise.resolve(res.data));
// }

// export const payAndAddTraining = async (token: any, body:any) => {
//     return await axiosClient.post("/payment",body,{headers:{token}}).then(res => Promise.resolve(res.data));
// }

// //Courses API Calls

// export const getCourses = async (token:any) => {
//     const headers = { token };
//     return await axiosClient.get("/courses", { headers }).then(res => Promise.resolve(res.data));
// }

// export const createCourses = async (token:any, body:any) => {
//     const headers = { token };
//     return await axiosClient.post("/courses",body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const updateCourses = async (token:any, body:any) => {
//     const headers = { token };
//     return await axiosClient.put("/courses",body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const deleteCourseAPI = async (token:any, id:any) => {
//     const headers = { token };
//     return await axiosClient.delete("/courses",{headers,params:{id}}).then(res => Promise.resolve(res.data));
//     // return await axiosClient.post("/courses",body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const CoursesQuery = async (id:any) => {
//     const params = { "id": id };
//     return await axiosClient.get("/coursequery", { params }).then(res => Promise.resolve(res.data));
// }

// export const getCourseMaterials=async(courseId:number,token:string)=>{
//     const headers={token};
//     return await axiosClient.get("/coursematerials",{headers,params:{courseId}}).then(res => Promise.resolve(res.data));
// }

// export const addCourseMaterial=async(token:string,body:any)=>{
//     return await axiosClient.post("/coursematerials",body,{headers:{token}}).then(res=>Promise.resolve(res.data));
// }

// export const deleteCourseMaterialAPI = async (token:string, query:any) => {
//     return await axiosClient.delete("/coursematerials",{headers:{token},params:query}).then(res=>Promise.resolve(res.data));
    
// }
// //Reviews API Calls

// export const getReviews = async (course_id:any,token:any) => {
//     const headers = { token };
//     const params = {course_id: course_id}
//     return await axiosClient.get("/reviews", { headers, params }).then(res => Promise.resolve(res.data));
// }

// export const createReviews = async (token:any, body:any) => {
//     const headers = { token };
//     // const body = { postDetail }
//     return await axiosClient.post("/reviews", body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const replyReviews = async (token:any, body:any) => {
//     const headers = { token };
//     return await axiosClient.put("/reviews", body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const editReviewsForTrainee = async (token:any, body:any) => {
//     const headers = { token };
//     return await axiosClient.put("/reviews", body, { headers }).then(res => Promise.resolve(res.data));
// }

// export const deleteReviews = async (token:string, statusId:any) => {
//     const params = { id: statusId };
//     return await axiosClient.delete("/reviews", {headers:{token},params:params}).then(res => Promise.resolve(res.data));
// }

// //Direct Request

// export const searchCourse = async (title:any) => {
//     const params = { "title": title };
//     return await axiosClient.get("/coursequery", { params }).then(res => Promise.resolve(res.data));
// }

// export const getDirectRequest = async (token:any, status:string) => {
//     const headers = { token };
//     const params = { "status": status };
//     return await axiosClient.get("/directrequests", { headers, params }).then(res => Promise.resolve(res.data));
// }

// export const createDirectRequest = async (token:any, body:any) => {
//     const headers = { token };
//     return await axiosClient.post("/directrequests", body, { headers }).then(res => Promise.resolve(res.data));
// }

// // export const bookDirectRequest = async (token:any, body:any) => {
// //     const headers = { token };
// //     return await axiosClient.post("/directrequests", body, { headers }).then(res => Promise.resolve(res.data));
// // }

// //Advertisements

// export const getAdvertisement = async (token:any) => {
//     const headers = { token };
//     return await axiosClient.get("advertisements", { headers }).then(res => Promise.resolve(res.data));
// }

// export const getAdminAdvertisement = async (token: any) => {
//     const headers = { token };
//     return await axiosClient
//         .get('/admin/advertisements', { headers })
//         .then((res) => Promise.resolve(res.data));
// };

// export const getSingleAdminAdvertisement = async (token: any, id: any) => {
//     const params = { id: id };
//     return await axiosClient
//         .get('/admin/advertisements', { headers: {token}, params: params })
//         .then((res) => Promise.resolve(res.data));
// };

// export const postAdvertisement = async (token: any, body: any) => {
//     const headers = { token };
//     return await axiosClient
//         .post('/admin/advertisements', body, { headers })
//         .then((res) => Promise.resolve(res.data));
// };

// export const putAdvertisement = async (token: any, body: any) => {
//     const headers = { token };
//     return await axiosClient
//         .put('/admin/advertisements', body, { headers })
//         .then((res) => Promise.resolve(res.data));
// };

// export const deleteAdvertisement = async (token: any, id: any) => {
//     const params = { id: id };
//     return await axiosClient
//         .delete('/admin/advertisements', {
//             headers: { token },
//             params: params,
//         })
//         .then((res) => Promise.resolve(res.data));
// };

// // Coupons

// export const getAllCoupons = async (token: any) => {
//     return await axiosClient
//         .get('/admin/coupons', { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// export const getSingleCoupon = async (token: any, id: any) => {
//     const params = { id: id };
//     return await axiosClient
//         .get('/admin/coupons', { headers: { token }, params: params })
//         .then((res) => Promise.resolve(res.data));
// };

// export const addCouponsForPost = async (token: any, body:any) => {
//     return await axiosClient
//         .post('/admin/coupons', body, { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// export const editCoupons = async (token: any, body: any) => {
//     return await axiosClient
//         .put('/admin/coupons', body, { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// export const deleteCoupons = async (token: any, id: any) => {
//     return await axiosClient
//         .delete('/admin/coupons', { headers: { token }, params: {id} })
//         .then((res) => Promise.resolve(res.data));
// };

// // Admin Config

// export const getAdminConfig = async (token: any) => {
//     return await axiosClient
//         .get('/admin/adminconfigs', {headers:{token}})
//         .then((res) => Promise.resolve(res.data));
// };

// export const setAdminConfig = async (token: any, body: any) => {
//     return await axiosClient
//         .put('/admin/adminconfigs', body, { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// // Remainder

// export const getReminderAPI = async (token: any) => {
//     return await axiosClient
//         .get('/admin/templates', { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// export const addTemplate = async (token: any, body: any) => {
//     return await axiosClient
//         .post('/admin/templates', body, { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// export const updateTemplate = async (token: any, body: any) => {
//     return await axiosClient
//         .put('/admin/templates', body, { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

// export const deleteReminderAPI = async (token: any, id: any) => {
//     const params = {id: id}
//     return await axiosClient
//         .delete('/admin/templates', { headers: { token }, params: params })
//         .then((res) => Promise.resolve(res.data));
// };

// // Admin for query profile

// export const queryProfileByAdmin = async (token: any) => {
//     // const params = { name: text };
//     return await axiosClient
//         .get('/admin/queryprofiles', { headers: { token } })
//         .then((res) => Promise.resolve(res.data));
// };

export const readRecord = async (
    params: any = {},
    endpoint: string,
    navigate: any = false
) => {
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    let response = await axiosClient
        .get(`/${endpoint}`, { headers, params })
        .then((res) => Promise.resolve(res));
    if (response.status === 401 && navigate) {
        return NavigateToSignInWithRouteParams(navigate);
    }
    return response.data;
};

export const postRecord = async (
    body: any,
    endpoint: string,
    navigate: any = false
) => {
    // const headers = { token };
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    const response = await axiosClient
        .post(`/${endpoint}`, body, { headers })
        .then((res) => Promise.resolve(res));
    if (response.status === 401 && navigate) {
        return NavigateToSignInWithRouteParams(navigate);
    }
    return response.data;
};

export const updateRecord = async (
    body: any,
    endpoint: string,
    navigate: any = false
) => {
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    const response = await axiosClient
        .put(`/${endpoint}`, body, { headers })
        .then((res) => Promise.resolve(res));
    if (response.status === 401 && navigate) {
        return NavigateToSignInWithRouteParams(navigate);
    }
    return response.data;
};

export const deleteRecord = async (
    params: any,
    endpoint: string,
    navigate: any = false
) => {
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    const response = await axiosClient
        .delete(`/${endpoint}`, { headers, params })
        .then((res) => Promise.resolve(res));
    if (response.status === 401 && navigate) {
        return NavigateToSignInWithRouteParams(navigate);
    }
    return response.data;
};