import { useState, useEffect } from 'react';
import Adupload from '../../assets/Images/Adupload.svg';
import { storage } from '../../Firebase/firebase';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import { parseFileNameFromStorageUrl } from '../Elements/ParseFileNameFromStorageUrl';
import { DragDrop } from '../Elements/DragandDrop';
import { RandomString } from '../RandomString/RandomString';
import toast from 'react-hot-toast';
import { MoonLoader } from 'react-spinners';
import uploadImage from '../../assets/Images/uploadImage.svg';
import deleteImage from '../../assets/Images/deleteImage.svg';
import { PulseLoader } from 'react-spinners';

export const DragAndDrop = ({ formik }: any) => {
    const [imageUrl, setImageUrl] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (formik.values.image.length) {
            setImageUrl(true);
            // setUploading(false);
        }
    }, [formik.values.image.length]);

    const resetImage = () => {
        setImageUrl(false);
    };

    const uploadResumeFile = async (files: any) => {
        setUploading(true);
        const randomKey = RandomString();
        const fileName = randomKey + '_advertisement';
        const file = files[0];
        const storageRef = ref(storage, `/adImage/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setImageUrl(true);
                    setUploading(false);
                    formik.setFieldValue('image', url);
                    const filename = file.name.split('.')[0];
                    formik.setFieldValue('file_name', filename);
                    toast.success('uploaded successfully');
                });
            }
        );
    };

    const deleteUploadedImage = async (url: string) => {
        console.log('entered');
        setUploading(true);
        const fileName = parseFileNameFromStorageUrl(url);
        const deleteStorageRef = ref(storage, `/adImage/${fileName}`);
        deleteObject(deleteStorageRef)
            .then(() => {
                setUploading(false);
                setImageUrl(false);
                formik.setFieldValue('image', '');
                formik.setFieldValue('file_name', '');
                toast.success(`File deleted successfully`);
            })
            .catch((err) => {
                console.log('error occurred');
            });
    };

    if (loading) {
        return (
            <div className="flex w-full min-h-[450px] items-center justify-center">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    return (
        <div className="flex flex-col w-10/12 mx-auto">
            {imageUrl ? (
                <div className="flex border">
                    <ImageUploadedContainer
                        resetImage={resetImage}
                        formik={formik}
                        uploading={uploading}
                        deleteImageFunc={() => {
                            setUploading(true);
                            deleteUploadedImage(formik.values.image);
                        }}
                    />
                    {formik.errors.image && (
                        <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-26px]">
                            {formik.errors.image}
                        </p>
                    )}
                </div>
            ) : (
                <div className="w-full">
                    <DragDrop
                        default={CourseDragAndDropContainer}
                        onUpload={(files: any) => {
                            setUploading(true);
                            uploadResumeFile(files);
                        }}
                    />
                    {formik.errors.image && (
                        <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-26px]">
                            {formik.errors.image}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

const ImageUploadedContainer = ({
    resetImage,
    deleteImageFunc,
    uploading,
    formik,
}: any) => {
    const [buttonActive, setButtonActive] = useState(false);
    return (
        <div className="h-[160px] rounded-[8px] relative w-full">
            {!uploading && (
                <img
                    src={formik.values.image}
                    className="h-full object-cover w-full rounded-[8px]"
                    alt="courseImage"
                />
            )}
            {uploading ? (
                <div className="absolute top-0 w-full h-full flex justify-center items-center">
                    <MoonLoader size={60} color={'#065FEF'} />
                </div>
            ) : (
                <div className="absolute top-0 w-full h-full flex justify-center items-center space-x-4">
                    {/* <button
                        type="button"
                        className="cursor-pointer flex justify-center items-center gap-2 text-[#065FEF] p-3 px-4 rounded-[4px] bg-white text-[12px] font-[400]"
                        title="uploadButton"
                        onClick={() => {
                            // deleteImageFunc();
                            resetImage();
                            setButtonActive(true);
                        }}
                        disabled={buttonActive}
                    >
                        <img alt="uploadIcon" src={uploadImage} />
                        Replace
                    </button> */}
                    <button
                        type="button"
                        className="cursor-pointer flex justify-center items-center gap-2 text-[#065FEF] p-3 px-4 rounded-[4px] bg-white text-[12px] font-[400]"
                        title="deleteButton"
                        onClick={() => {
                            deleteImageFunc();
                            setButtonActive(true);
                        }}
                        disabled={buttonActive}
                    >
                        <img alt="deleteIcon" src={deleteImage} />
                        Delete
                    </button>
                </div>
            )}
        </div>
    );
};

const CourseDragAndDropContainer = ({ inputProps }: any) => {
    return (
        <div className="w-full mt-2 h-[160px] bg-[#F9FAFC] outline-dashed outline-2 outline-[#1F74E5] outline-offset-1 rounded-[8px] flex flex-col justify-center items-center gap-2">
            <img src={Adupload} alt="dragDropImage" />
            <label className="text-[13px] font-[400]">
                Drag and drop an image, or{' '}
                <span className="text-[#1F74E5] text-[15px] font-[500] cursor-pointer">
                    Click Here
                </span>
            </label>
        </div>
    );
};
