import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    appConfig:{}
};

const appConfigSlice = createSlice({
    name: 'getAd',
    initialState,
    reducers: {
        setAppConfig: (state: any, action: PayloadAction<any>) => {
            state.appConfig = { ...state.appConfig, ...action.payload };
        }
    }
});

export const { setAppConfig} =
    appConfigSlice.actions;
export default appConfigSlice.reducer;
