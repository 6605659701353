const RESPONSE_CAMEL_AVOID_WORDS = [
    'in',
    'the',
    'with',
    'and',
    'or',
    'not',
    "didn't",
    'is',
    'this',
    'without',
    'your',
    'cannot',
    'be',
    'id',
    'make',
    'are',
    'which',
    'added',
];

export const camelCaseConvertForErrorMessage = (message: any) => {
    const messageArray = message.split(' ');
    const convertedArray = messageArray.map((msg: any) => {
        if (RESPONSE_CAMEL_AVOID_WORDS.includes(msg)) return msg;
        let result = msg.slice(0, msg.length).toLowerCase();
        return result.charAt(0).toUpperCase() + result.slice(1);
    });
    return convertedArray.join(' ');
};

export const NavigateToSignInWithRouteParams = (navigate: any) => {
    const locationHref = window.location.href;
    const fromRoute = locationHref.split(window.location.host)[1];
    navigate('/', { state: { from: fromRoute } });
};
