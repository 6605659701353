// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import { readRecord, updateRecord } from '../../Services/backend/apiCalls';

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         setFormData([...formData, newSection]);
//         setOpenSectionIndex(formData.length);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }
//             console.log('Data updated successfully');
//         } catch (error) {
//             console.error('Error updating data:', error);
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Opportunity</h1>
//             <form onSubmit={handleSubmit}>
//                 {formData.map((section, sectionIndex) => (
//                     <div key={section.id || sectionIndex} className="mb-6">
//                         <button
//                             type="button"
//                             className="flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg"
//                             onClick={() => toggleSection(sectionIndex)}
//                         >
//                             <span>{section.data.title}</span>
//                             <span
//                                 className={`transform transition-transform duration-300 ${
//                                     openSectionIndex === sectionIndex
//                                         ? 'rotate-180'
//                                         : 'rotate-0'
//                                 }`}
//                             >
//                                 <ChevronUpIcon className="w-5 h-5" />
//                             </span>
//                         </button>
//                         {openSectionIndex === sectionIndex && (
//                             <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                 <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                     <label className="block text-sm font-medium text-gray-700 w-full">
//                                         Title:
//                                         <input
//                                             type="text"
//                                             value={section.data.title}
//                                             onChange={(e) =>
//                                                 handleInputChange(
//                                                     sectionIndex,
//                                                     null,
//                                                     'title',
//                                                     e.target.value
//                                                 )
//                                             }
//                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                         />
//                                     </label>
//                                 </div>
//                                 {section.data.content.map(
//                                     (item, contentIndex) => (
//                                         <div
//                                             key={contentIndex}
//                                             className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                         >
//                                             <div className="w-full">
//                                                 <label className="block text-sm font-medium text-gray-700">
//                                                     Label:
//                                                     <input
//                                                         type="text"
//                                                         value={item.label}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'label',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     URL:
//                                                     <input
//                                                         type="text"
//                                                         value={item.url}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'url',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     Action Keyword:
//                                                     <input
//                                                         type="text"
//                                                         value={
//                                                             item.actionKeyword
//                                                         }
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'actionKeyword',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                             </div>
//                                         </div>
//                                     )
//                                 )}
//                             </div>
//                         )}
//                     </div>
//                 ))}
//                 <button
//                     type="button"
//                     className=" bg-[#E5ECFF] py-2 px-4 p-3  rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center    border border-black-2 border-transparent shadow-sm text-sm    focus:outline-none focus:ring-2 focus:ring-offset-2 "
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//                 <button
//                     type="submit"
//                     className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 >
//                     Save Changes
//                 </button>
//             </form>
//         </div>
//     );
// };

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         setFormData([...formData, newSection]);
//         setOpenSectionIndex(formData.length);
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//     };

//     const handleRemoveSection = async (sectionIndex) => {
//         const section = formData[sectionIndex];
//         if (section.id) {
//             try {
//                 await deleteRecord({}, `editOpportunityData/${section.id}`);
//                 toast.success('Section deleted successfully');
//             } catch (error) {
//                 console.error('Error deleting section:', error);
//                 toast.error('Error deleting section');
//                 return;
//             }
//         }
//         const updatedFormData = formData.filter(
//             (_, index) => index !== sectionIndex
//         );
//         setFormData(updatedFormData);
//         setOpenSectionIndex(null);
//     };

//     const handleRemoveItem = async (sectionIndex, contentIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content = updatedFormData[
//             sectionIndex
//         ].data.content.filter((_, index) => index !== contentIndex);
//         setFormData(updatedFormData);

//         // Update the server with the new section data
//         const { id, data } = updatedFormData[sectionIndex];
//         if (id) {
//             try {
//                 await updateRecord({ id, data }, 'editOpportunityData');
//                 toast.success('Item deleted successfully');
//             } catch (error) {
//                 console.error('Error deleting item:', error);
//                 toast.error('Error deleting item');
//             }
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }
//             toast.success('Data updated successfully');
//             setOpenSectionIndex(null); // Close the currently open section
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error('Error updating data');
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setLoading(false);
//                         console.log(data.data);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Trending Course</h1>
//             <form onSubmit={handleSubmit}>
//                 {formData.map((section, sectionIndex) => (
//                     <div key={section.id || sectionIndex} className="mb-6">
//                         <button
//                             type="button"
//                             className="flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg"
//                             onClick={() => toggleSection(sectionIndex)}
//                         >
//                             <span>{section.data.title}</span>
//                             <span
//                                 className={`transform transition-transform duration-300 ${
//                                     openSectionIndex === sectionIndex
//                                         ? 'rotate-180'
//                                         : 'rotate-0'
//                                 }`}
//                             >
//                                 <ChevronUpIcon className="w-5 h-5" />
//                             </span>
//                         </button>
//                         {openSectionIndex === sectionIndex && (
//                             <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                 <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                     <label className="block text-sm font-medium text-gray-700 w-full">
//                                         Title:
//                                         <input
//                                             type="text"
//                                             value={section.data.title}
//                                             onChange={(e) =>
//                                                 handleInputChange(
//                                                     sectionIndex,
//                                                     null,
//                                                     'title',
//                                                     e.target.value
//                                                 )
//                                             }
//                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                         />
//                                     </label>
//                                     <button
//                                         type="button"
//                                         className="ml-4 text-red-600 hover:text-red-900"
//                                         onClick={() =>
//                                             handleRemoveSection(sectionIndex)
//                                         }
//                                     >
//                                         <TrashIcon className="w-5 h-5" />
//                                     </button>
//                                 </div>
//                                 {section.data.content.map(
//                                     (item, contentIndex) => (
//                                         <div
//                                             key={contentIndex}
//                                             className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                         >
//                                             <div className="w-full">
//                                                 <label className="block text-sm font-medium text-gray-700">
//                                                     Label:
//                                                     <input
//                                                         type="text"
//                                                         value={item.label}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'label',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     URL:
//                                                     <input
//                                                         type="text"
//                                                         value={item.url}
//                                                         disabled
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'url',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     Action Keyword:
//                                                     <input
//                                                         type="text"
//                                                         value={
//                                                             item.actionKeyword
//                                                         }
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'actionKeyword',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                             </div>
//                                             <button
//                                                 type="button"
//                                                 className="ml-4 text-red-600 hover:text-red-900"
//                                                 onClick={() =>
//                                                     handleRemoveItem(
//                                                         sectionIndex,
//                                                         contentIndex
//                                                     )
//                                                 }
//                                             >
//                                                 <TrashIcon className="w-5 h-5" />
//                                             </button>
//                                         </div>
//                                     )
//                                 )}
//                                 <button
//                                     type="button"
//                                     className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                     onClick={() => handleAddItem(sectionIndex)}
//                                 >
//                                     Add Item
//                                     <PlusIcon className="w-5 h-5 ml-2" />
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 ))}
//                 <button
//                     type="button"
//                     className=" bg-[#E5ECFF] py-2 px-4 p-3  rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center    border border-black-2 border-transparent shadow-sm text-sm    focus:outline-none focus:ring-2 focus:ring-offset-2 "
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//                 <button
//                     type="submit"
//                     className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 >
//                     Save Changes
//                 </button>
//             </form>
//         </div>
//     );
// };

// add postion logic

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         setFormData([...formData, newSection]);
//         setOpenSectionIndex(formData.length);
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//     };

//     const handleRemoveSection = async (sectionIndex) => {
//         const section = formData[sectionIndex];
//         if (section.id) {
//             try {
//                 await deleteRecord({}, `editOpportunityData/${section.id}`);
//                 toast.success('Section deleted successfully');
//             } catch (error) {
//                 console.error('Error deleting section:', error);
//                 toast.error('Error deleting section');
//                 return;
//             }
//         }
//         const updatedFormData = formData.filter(
//             (_, index) => index !== sectionIndex
//         );
//         setFormData(updatedFormData);
//         setOpenSectionIndex(null);
//     };

//     const handleRemoveItem = async (sectionIndex, contentIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content = updatedFormData[
//             sectionIndex
//         ].data.content.filter((_, index) => index !== contentIndex);
//         setFormData(updatedFormData);

//         // Update the server with the new section data
//         const { id, data } = updatedFormData[sectionIndex];
//         if (id) {
//             try {
//                 await updateRecord({ id, data }, 'editOpportunityData');
//                 toast.success('Item deleted successfully');
//             } catch (error) {
//                 console.error('Error deleting item:', error);
//                 toast.error('Error deleting item');
//             }
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }
//             toast.success('Data updated successfully');
//             setOpenSectionIndex(null); // Close the currently open section
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error('Error updating data');
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Opportunity</h1>
//             <form onSubmit={handleSubmit}>
//                 {formData
//                     .sort((a, b) => a.id - b.id)
//                     .map((section, sectionIndex) => (
//                         <div key={section.id || sectionIndex} className="mb-6">
//                             <button
//                                 type="button"
//                                 className="flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg"
//                                 onClick={() => toggleSection(sectionIndex)}
//                             >
//                                 <span>{section.data.title}</span>
//                                 <span
//                                     className={`transform transition-transform duration-300 ${
//                                         openSectionIndex === sectionIndex
//                                             ? 'rotate-180'
//                                             : 'rotate-0'
//                                     }`}
//                                 >
//                                     <ChevronUpIcon className="w-5 h-5" />
//                                 </span>
//                             </button>
//                             {openSectionIndex === sectionIndex && (
//                                 <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                     <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                         <label className="block text-sm font-medium text-gray-700 w-full">
//                                             Title:
//                                             <input
//                                                 type="text"
//                                                 value={section.data.title}
//                                                 onChange={(e) =>
//                                                     handleInputChange(
//                                                         sectionIndex,
//                                                         null,
//                                                         'title',
//                                                         e.target.value
//                                                     )
//                                                 }
//                                                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                             />
//                                         </label>
//                                         <button
//                                             type="button"
//                                             className="ml-4 text-red-600 hover:text-red-900"
//                                             onClick={() =>
//                                                 handleRemoveSection(
//                                                     sectionIndex
//                                                 )
//                                             }
//                                         >
//                                             <TrashIcon className="w-5 h-5" />
//                                         </button>
//                                     </div>
//                                     {section.data.content.map(
//                                         (item, contentIndex) => (
//                                             <div
//                                                 key={contentIndex}
//                                                 className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                             >
//                                                 <div className="w-full">
//                                                     <label className="block text-sm font-medium text-gray-700">
//                                                         Label:
//                                                         <input
//                                                             type="text"
//                                                             value={item.label}
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'label',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                     <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                         URL:
//                                                         <input
//                                                             type="text"
//                                                             value={item.url}
//                                                             disabled
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'url',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                     <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                         Action Keyword:
//                                                         <input
//                                                             type="text"
//                                                             value={
//                                                                 item.actionKeyword
//                                                             }
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'actionKeyword',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                 </div>
//                                                 <button
//                                                     type="button"
//                                                     className="ml-4 text-red-600 hover:text-red-900"
//                                                     onClick={() =>
//                                                         handleRemoveItem(
//                                                             sectionIndex,
//                                                             contentIndex
//                                                         )
//                                                     }
//                                                 >
//                                                     <TrashIcon className="w-5 h-5" />
//                                                 </button>
//                                             </div>
//                                         )
//                                     )}
//                                     <button
//                                         type="button"
//                                         className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                         onClick={() =>
//                                             handleAddItem(sectionIndex)
//                                         }
//                                     >
//                                         Add Item
//                                         <PlusIcon className="w-5 h-5 ml-2" />
//                                     </button>
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 <button
//                     type="button"
//                     className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//                 <button
//                     type="submit"
//                     className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 >
//                     Save Changes
//                 </button>
//             </form>
//         </div>
//     );
// };

// added sticky save button

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         setFormData([...formData, newSection]);
//         setOpenSectionIndex(formData.length);
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//     };

//     const handleRemoveSection = async (sectionIndex) => {
//         const section = formData[sectionIndex];
//         if (section.id) {
//             try {
//                 await deleteRecord({}, `editOpportunityData/${section.id}`);
//                 toast.success('Section deleted successfully');
//             } catch (error) {
//                 console.error('Error deleting section:', error);
//                 toast.error('Error deleting section');
//                 return;
//             }
//         }
//         const updatedFormData = formData.filter(
//             (_, index) => index !== sectionIndex
//         );
//         setFormData(updatedFormData);
//         setOpenSectionIndex(null);
//     };

//     const handleRemoveItem = async (sectionIndex, contentIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content = updatedFormData[
//             sectionIndex
//         ].data.content.filter((_, index) => index !== contentIndex);
//         setFormData(updatedFormData);

//         // Update the server with the new section data
//         const { id, data } = updatedFormData[sectionIndex];
//         if (id) {
//             try {
//                 await updateRecord({ id, data }, 'editOpportunityData');
//                 toast.success('Item deleted successfully');
//             } catch (error) {
//                 console.error('Error deleting item:', error);
//                 toast.error('Error deleting item');
//             }
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }
//             toast.success('Data updated successfully');
//             setOpenSectionIndex(null); // Close the currently open section
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error('Error updating data');
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="relative p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Opportunity</h1>
//             <form onSubmit={handleSubmit} className="pb-20">
//                 {' '}
//                 {/* Add padding to avoid content being hidden behind the footer */}
//                 {formData
//                     .sort((a, b) => a.id - b.id)
//                     .map((section, sectionIndex) => (
//                         <div key={section.id || sectionIndex} className="mb-6">
//                             <button
//                                 type="button"
//                                 className="flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg"
//                                 onClick={() => toggleSection(sectionIndex)}
//                             >
//                                 <span>{section.data.title}</span>
//                                 <span
//                                     className={`transform transition-transform duration-300 ${
//                                         openSectionIndex === sectionIndex
//                                             ? 'rotate-180'
//                                             : 'rotate-0'
//                                     }`}
//                                 >
//                                     <ChevronUpIcon className="w-5 h-5" />
//                                 </span>
//                             </button>
//                             {openSectionIndex === sectionIndex && (
//                                 <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                     <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                         <label className="block text-sm font-medium text-gray-700 w-full">
//                                             Title:
//                                             <input
//                                                 type="text"
//                                                 value={section.data.title}
//                                                 onChange={(e) =>
//                                                     handleInputChange(
//                                                         sectionIndex,
//                                                         null,
//                                                         'title',
//                                                         e.target.value
//                                                     )
//                                                 }
//                                                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                             />
//                                         </label>
//                                         <button
//                                             type="button"
//                                             className="ml-4 text-red-600 hover:text-red-900"
//                                             onClick={() =>
//                                                 handleRemoveSection(
//                                                     sectionIndex
//                                                 )
//                                             }
//                                         >
//                                             <TrashIcon className="w-5 h-5" />
//                                         </button>
//                                     </div>
//                                     {section.data.content.map(
//                                         (item, contentIndex) => (
//                                             <div
//                                                 key={contentIndex}
//                                                 className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                             >
//                                                 <div className="w-full">
//                                                     <label className="block text-sm font-medium text-gray-700">
//                                                         Label:
//                                                         <input
//                                                             type="text"
//                                                             value={item.label}
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'label',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                     <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                         URL:
//                                                         <input
//                                                             type="text"
//                                                             value={item.url}
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'url',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                     <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                         Action Keyword:
//                                                         <input
//                                                             type="text"
//                                                             value={
//                                                                 item.actionKeyword
//                                                             }
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'actionKeyword',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                 </div>
//                                                 <button
//                                                     type="button"
//                                                     className="ml-4 text-red-600 hover:text-red-900"
//                                                     onClick={() =>
//                                                         handleRemoveItem(
//                                                             sectionIndex,
//                                                             contentIndex
//                                                         )
//                                                     }
//                                                 >
//                                                     <TrashIcon className="w-5 h-5" />
//                                                 </button>
//                                             </div>
//                                         )
//                                     )}
//                                     <button
//                                         type="button"
//                                         className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                         onClick={() =>
//                                             handleAddItem(sectionIndex)
//                                         }
//                                     >
//                                         Add Item
//                                         <PlusIcon className="w-5 h-5 ml-2" />
//                                     </button>
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 <button
//                     type="button"
//                     className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//             </form>
//             <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-end">
//                 <button
//                     type="button"
//                     className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                     onClick={handleSubmit}
//                 >
//                     Save Changes
//                 </button>
//             </div>
//         </div>
//     );
// };

// added validation

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';
// import Modal from 'react-modal';

// Modal.setAppElement('#root'); // This is important for accessibility

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [saving, setSaving] = useState(false);
//     const [error, setError] = useState(null);
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [itemToDelete, setItemToDelete] = useState(null);
//     const [validationErrors, setValidationErrors] = useState({});

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         setFormData([...formData, newSection]);
//         setOpenSectionIndex(formData.length);
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//     };

//     const handleRemoveSection = (sectionIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex: null });
//         setModalIsOpen(true);
//     };

//     const handleRemoveItem = (sectionIndex, contentIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex });
//         setModalIsOpen(true);
//     };

//     const confirmDelete = async () => {
//         const { sectionIndex, contentIndex } = itemToDelete;
//         if (contentIndex === null) {
//             const section = formData[sectionIndex];
//             if (section.id) {
//                 try {
//                     await deleteRecord({}, `editOpportunityData/${section.id}`);
//                     toast.success('Section deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting section:', error);
//                     toast.error('Error deleting section');
//                     setModalIsOpen(false);
//                     return;
//                 }
//             }
//             const updatedFormData = formData.filter(
//                 (_, index) => index !== sectionIndex
//             );
//             setFormData(updatedFormData);
//             setOpenSectionIndex(null);
//         } else {
//             const updatedFormData = [...formData];
//             updatedFormData[sectionIndex].data.content = updatedFormData[
//                 sectionIndex
//             ].data.content.filter((_, index) => index !== contentIndex);
//             setFormData(updatedFormData);

//             // Update the server with the new section data
//             const { id, data } = updatedFormData[sectionIndex];
//             if (id) {
//                 try {
//                     await updateRecord({ id, data }, 'editOpportunityData');
//                     toast.success('Item deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting item:', error);
//                     toast.error('Error deleting item');
//                 }
//             }
//         }
//         setModalIsOpen(false);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setSaving(true);
//         const errors = {};

//         try {
//             // Validate form data
//             formData.forEach((section, sectionIndex) => {
//                 if (!section.data.title.trim()) {
//                     if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                     errors[sectionIndex].title = 'Section title is required';
//                 }
//                 section.data.content.forEach((item, contentIndex) => {
//                     if (!item.label.trim()) {
//                         if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                         if (!errors[sectionIndex].content)
//                             errors[sectionIndex].content = {};
//                         errors[sectionIndex].content[contentIndex] =
//                             'Item label is required';
//                     }
//                 });
//             });

//             if (Object.keys(errors).length > 0) {
//                 throw new Error('Validation failed');
//             }

//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }

//             toast.success('Data updated successfully');
//             setValidationErrors({});
//             setOpenSectionIndex(null); // Close the currently open section
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error(error.message || 'Error updating data');
//             setValidationErrors(errors);

//             // Automatically open sections with validation errors
//             const firstErrorSectionIndex = parseInt(Object.keys(errors)[0], 10);
//             setOpenSectionIndex(firstErrorSectionIndex);
//         } finally {
//             setSaving(false);
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="relative p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Opportunity</h1>
//             <form onSubmit={handleSubmit} className="pb-20">
//                 {' '}
//                 {/* Add padding to avoid content being hidden behind the footer */}
//                 {formData
//                     .sort((a, b) => a.id - b.id)
//                     .map((section, sectionIndex) => (
//                         <div key={section.id || sectionIndex} className="mb-6">
//                             <button
//                                 type="button"
//                                 className={`flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg ${
//                                     validationErrors[sectionIndex]?.title
//                                         ? 'border-red-500'
//                                         : ''
//                                 }`}
//                                 onClick={() => toggleSection(sectionIndex)}
//                             >
//                                 <span>{section.data.title}</span>
//                                 <span
//                                     className={`transform transition-transform duration-300 ${
//                                         openSectionIndex === sectionIndex
//                                             ? 'rotate-180'
//                                             : 'rotate-0'
//                                     }`}
//                                 >
//                                     <ChevronUpIcon className="w-5 h-5" />
//                                 </span>
//                             </button>
//                             {openSectionIndex === sectionIndex && (
//                                 <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                     <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                         <label className="block text-sm font-medium text-gray-700 w-full">
//                                             Title:
//                                             <input
//                                                 type="text"
//                                                 value={section.data.title}
//                                                 onChange={(e) =>
//                                                     handleInputChange(
//                                                         sectionIndex,
//                                                         null,
//                                                         'title',
//                                                         e.target.value
//                                                     )
//                                                 }
//                                                 className={`mt-1 block w-full px-3 py-2 border ${
//                                                     validationErrors[
//                                                         sectionIndex
//                                                     ]?.title
//                                                         ? 'border-red-500'
//                                                         : 'border-gray-300'
//                                                 } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                 required
//                                             />
//                                             {validationErrors[sectionIndex]
//                                                 ?.title && (
//                                                 <p className="text-red-500 text-sm mt-1">
//                                                     {
//                                                         validationErrors[
//                                                             sectionIndex
//                                                         ].title
//                                                     }
//                                                 </p>
//                                             )}
//                                         </label>
//                                         <button
//                                             type="button"
//                                             className="ml-4 text-red-600 hover:text-red-900"
//                                             onClick={() =>
//                                                 handleRemoveSection(
//                                                     sectionIndex
//                                                 )
//                                             }
//                                         >
//                                             <TrashIcon className="w-5 h-5" />
//                                         </button>
//                                     </div>
//                                     {section.data.content.map(
//                                         (item, contentIndex) => (
//                                             <div
//                                                 key={contentIndex}
//                                                 className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                             >
//                                                 <div className="w-full">
//                                                     <label className="block text-sm font-medium text-gray-700">
//                                                         Label:
//                                                         <input
//                                                             type="text"
//                                                             value={item.label}
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'label',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className={`mt-1 block w-full px-3 py-2 border ${
//                                                                 validationErrors[
//                                                                     sectionIndex
//                                                                 ]?.content?.[
//                                                                     contentIndex
//                                                                 ]
//                                                                     ? 'border-red-500'
//                                                                     : 'border-gray-300'
//                                                             } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                             required
//                                                         />
//                                                         {validationErrors[
//                                                             sectionIndex
//                                                         ]?.content?.[
//                                                             contentIndex
//                                                         ] && (
//                                                             <p className="text-red-500 text-sm mt-1">
//                                                                 {
//                                                                     validationErrors[
//                                                                         sectionIndex
//                                                                     ].content[
//                                                                         contentIndex
//                                                                     ]
//                                                                 }
//                                                             </p>
//                                                         )}
//                                                     </label>
//                                                     <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                         URL:
//                                                         <input
//                                                             type="text"
//                                                             value={item.url}
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'url',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                             disabled
//                                                         />
//                                                     </label>
//                                                     <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                         Action Keyword:
//                                                         <input
//                                                             type="text"
//                                                             value={
//                                                                 item.actionKeyword
//                                                             }
//                                                             onChange={(e) =>
//                                                                 handleInputChange(
//                                                                     sectionIndex,
//                                                                     contentIndex,
//                                                                     'actionKeyword',
//                                                                     e.target
//                                                                         .value
//                                                                 )
//                                                             }
//                                                             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         />
//                                                     </label>
//                                                 </div>
//                                                 <button
//                                                     type="button"
//                                                     className="ml-4 text-red-600 hover:text-red-900"
//                                                     onClick={() =>
//                                                         handleRemoveItem(
//                                                             sectionIndex,
//                                                             contentIndex
//                                                         )
//                                                     }
//                                                 >
//                                                     <TrashIcon className="w-5 h-5" />
//                                                 </button>
//                                             </div>
//                                         )
//                                     )}
//                                     <button
//                                         type="button"
//                                         className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                         onClick={() =>
//                                             handleAddItem(sectionIndex)
//                                         }
//                                     >
//                                         Add Item
//                                         <PlusIcon className="w-5 h-5 ml-2" />
//                                     </button>
//                                 </div>
//                             )}
//                         </div>
//                     ))}
//                 <button
//                     type="button"
//                     className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//             </form>
//             <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-end">
//                 <button
//                     type="submit"
//                     className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
//                         saving
//                             ? 'bg-gray-600'
//                             : 'bg-indigo-600 hover:bg-indigo-700'
//                     } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
//                     disabled={saving}
//                     onClick={handleSubmit}
//                 >
//                     {saving ? 'Saving...' : 'Save Changes'}
//                 </button>
//             </div>
//             <Modal
//                 isOpen={modalIsOpen}
//                 onRequestClose={() => setModalIsOpen(false)}
//                 contentLabel="Confirmation Modal"
//                 className="fixed inset-0 flex items-center justify-center"
//                 overlayClassName="fixed inset-0 bg-black bg-opacity-50"
//             >
//                 <div className="bg-white p-6 rounded shadow-lg text-center">
//                     <h2 className="text-xl font-semibold mb-4">
//                         Confirm Deletion
//                     </h2>
//                     <p className="mb-4">
//                         Are you sure you want to delete this item?
//                     </p>
//                     <button
//                         className="bg-red-600 text-white py-2 px-4 rounded mr-2"
//                         onClick={confirmDelete}
//                     >
//                         Yes
//                     </button>
//                     <button
//                         className="bg-gray-300 py-2 px-4 rounded"
//                         onClick={() => setModalIsOpen(false)}
//                     >
//                         No
//                     </button>
//                 </div>
//             </Modal>
//         </div>
//     );
// };

// update with alll valiudation

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';
// import Modal from 'react-modal';

// Modal.setAppElement('#root'); // This is important for accessibility

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [saving, setSaving] = useState(false);
//     const [error, setError] = useState(null);
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [itemToDelete, setItemToDelete] = useState(null);
//     const [validationErrors, setValidationErrors] = useState({});

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         setFormData([...formData, newSection]);
//         setOpenSectionIndex(formData.length);
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//     };

//     const handleRemoveSection = (sectionIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex: null });
//         setModalIsOpen(true);
//     };

//     const handleRemoveItem = (sectionIndex, contentIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex });
//         setModalIsOpen(true);
//     };

//     const confirmDelete = async () => {
//         const { sectionIndex, contentIndex } = itemToDelete;
//         if (contentIndex === null) {
//             const section = formData[sectionIndex];
//             if (section.id) {
//                 try {
//                     await deleteRecord({}, `editOpportunityData/${section.id}`);
//                     toast.success('Section deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting section:', error);
//                     toast.error('Error deleting section');
//                     setModalIsOpen(false);
//                     return;
//                 }
//             }
//             const updatedFormData = formData.filter(
//                 (_, index) => index !== sectionIndex
//             );
//             setFormData(updatedFormData);
//             setOpenSectionIndex(null);
//         } else {
//             const updatedFormData = [...formData];
//             updatedFormData[sectionIndex].data.content = updatedFormData[
//                 sectionIndex
//             ].data.content.filter((_, index) => index !== contentIndex);
//             setFormData(updatedFormData);

//             // Update the server with the new section data
//             const { id, data } = updatedFormData[sectionIndex];
//             if (id) {
//                 try {
//                     await updateRecord({ id, data }, 'editOpportunityData');
//                     toast.success('Item deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting item:', error);
//                     toast.error('Error deleting item');
//                 }
//             }
//         }
//         setModalIsOpen(false);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setSaving(true);
//         const errors = {};

//         try {
//             // Validate form data
//             formData.forEach((section, sectionIndex) => {
//                 if (!section.data.title.trim()) {
//                     if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                     errors[sectionIndex].title = 'Section title is required';
//                 }
//                 section.data.content.forEach((item, contentIndex) => {
//                     if (!item.label.trim()) {
//                         if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                         if (!errors[sectionIndex].content)
//                             errors[sectionIndex].content = {};
//                         errors[sectionIndex].content[contentIndex] =
//                             'Item label is required';
//                     }
//                 });
//             });

//             if (Object.keys(errors).length > 0) {
//                 throw new Error('Validation failed');
//             }

//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }

//             toast.success('Data updated successfully');
//             setValidationErrors({});
//             setOpenSectionIndex(null); // Close the currently open section
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error(error.message || 'Error updating data');
//             setValidationErrors(errors);

//             // Automatically open sections with validation errors
//             const firstErrorSectionIndex = parseInt(Object.keys(errors)[0], 10);
//             setOpenSectionIndex(firstErrorSectionIndex);
//         } finally {
//             setSaving(false);
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="relative p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Opportunity</h1>
//             <form onSubmit={handleSubmit} className="pb-20">
//                 {' '}
//                 {/* Add padding to avoid content being hidden behind the footer */}
//                 {formData.map((section, sectionIndex) => (
//                     <div key={section.id || sectionIndex} className="mb-6">
//                         <button
//                             type="button"
//                             className={`flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg ${
//                                 validationErrors[sectionIndex]?.title
//                                     ? 'border-red-500'
//                                     : ''
//                             }`}
//                             onClick={() => toggleSection(sectionIndex)}
//                         >
//                             <span>{section.data.title}</span>
//                             <span
//                                 className={`transform transition-transform duration-300 ${
//                                     openSectionIndex === sectionIndex
//                                         ? 'rotate-180'
//                                         : 'rotate-0'
//                                 }`}
//                             >
//                                 <ChevronUpIcon className="w-5 h-5" />
//                             </span>
//                         </button>
//                         {openSectionIndex === sectionIndex && (
//                             <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                 <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                     <label className="block text-sm font-medium text-gray-700 w-full">
//                                         Title:
//                                         <input
//                                             type="text"
//                                             value={section.data.title}
//                                             onChange={(e) =>
//                                                 handleInputChange(
//                                                     sectionIndex,
//                                                     null,
//                                                     'title',
//                                                     e.target.value
//                                                 )
//                                             }
//                                             className={`mt-1 block w-full px-3 py-2 border ${
//                                                 validationErrors[sectionIndex]
//                                                     ?.title
//                                                     ? 'border-red-500'
//                                                     : 'border-gray-300'
//                                             } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                             required
//                                         />
//                                         {validationErrors[sectionIndex]
//                                             ?.title && (
//                                             <p className="text-red-500 text-sm mt-1">
//                                                 {
//                                                     validationErrors[
//                                                         sectionIndex
//                                                     ].title
//                                                 }
//                                             </p>
//                                         )}
//                                     </label>
//                                     <button
//                                         type="button"
//                                         className="ml-4 text-red-600 hover:text-red-900"
//                                         onClick={() =>
//                                             handleRemoveSection(sectionIndex)
//                                         }
//                                     >
//                                         <TrashIcon className="w-5 h-5" />
//                                     </button>
//                                 </div>
//                                 {section.data.content.map(
//                                     (item, contentIndex) => (
//                                         <div
//                                             key={contentIndex}
//                                             className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                         >
//                                             <div className="w-full">
//                                                 <label className="block text-sm font-medium text-gray-700">
//                                                     Label:
//                                                     <input
//                                                         type="text"
//                                                         value={item.label}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'label',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className={`mt-1 block w-full px-3 py-2 border ${
//                                                             validationErrors[
//                                                                 sectionIndex
//                                                             ]?.content?.[
//                                                                 contentIndex
//                                                             ]
//                                                                 ? 'border-red-500'
//                                                                 : 'border-gray-300'
//                                                         } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                         required
//                                                     />
//                                                     {validationErrors[
//                                                         sectionIndex
//                                                     ]?.content?.[
//                                                         contentIndex
//                                                     ] && (
//                                                         <p className="text-red-500 text-sm mt-1">
//                                                             {
//                                                                 validationErrors[
//                                                                     sectionIndex
//                                                                 ].content[
//                                                                     contentIndex
//                                                                 ]
//                                                             }
//                                                         </p>
//                                                     )}
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     URL:
//                                                     <input
//                                                         type="text"
//                                                         value={item.url}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'url',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         disabled
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     Action Keyword:
//                                                     <input
//                                                         type="text"
//                                                         value={
//                                                             item.actionKeyword
//                                                         }
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'actionKeyword',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                     />
//                                                 </label>
//                                             </div>
//                                             <button
//                                                 type="button"
//                                                 className="ml-4 text-red-600 hover:text-red-900"
//                                                 onClick={() =>
//                                                     handleRemoveItem(
//                                                         sectionIndex,
//                                                         contentIndex
//                                                     )
//                                                 }
//                                             >
//                                                 <TrashIcon className="w-5 h-5" />
//                                             </button>
//                                         </div>
//                                     )
//                                 )}
//                                 <button
//                                     type="button"
//                                     className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                     onClick={() => handleAddItem(sectionIndex)}
//                                 >
//                                     Add Item
//                                     <PlusIcon className="w-5 h-5 ml-2" />
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 ))}
//                 <button
//                     type="button"
//                     className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//             </form>
//             <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-end">
//                 <button
//                     type="submit"
//                     className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
//                         saving
//                             ? 'bg-gray-600'
//                             : 'bg-indigo-600 hover:bg-indigo-700'
//                     } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
//                     disabled={saving}
//                     onClick={handleSubmit}
//                 >
//                     {saving ? 'Saving...' : 'Save Changes'}
//                 </button>
//             </div>
//             <Modal
//                 isOpen={modalIsOpen}
//                 onRequestClose={() => setModalIsOpen(false)}
//                 contentLabel="Confirmation Modal"
//                 className="fixed inset-0 flex items-center justify-center"
//                 overlayClassName="fixed inset-0 bg-black bg-opacity-50"
//             >
//                 <div className="bg-white p-6 rounded shadow-lg text-center">
//                     <h2 className="text-xl font-semibold mb-4">
//                         Confirm Deletion
//                     </h2>
//                     <p className="mb-4">
//                         Are you sure you want to delete this item?
//                     </p>
//                     <button
//                         className="bg-red-600 text-white py-2 px-4 rounded mr-2"
//                         onClick={confirmDelete}
//                     >
//                         Yes
//                     </button>
//                     <button
//                         className="bg-gray-300 py-2 px-4 rounded"
//                         onClick={() => setModalIsOpen(false)}
//                     >
//                         No
//                     </button>
//                 </div>
//             </Modal>
//         </div>
//     );
// };

// add validation to action keyword as well

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';
// import Modal from 'react-modal';
// import { ClipLoader } from 'react-spinners'; // Import a spinner loader
// import isEqual from 'lodash.isequal'; // Import lodash's isEqual for deep comparison

// Modal.setAppElement('#root'); // This is important for accessibility

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [initialFormData, setInitialFormData] = useState([]); // Store initial form data
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [saving, setSaving] = useState(false);
//     const [error, setError] = useState(null);
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [itemToDelete, setItemToDelete] = useState(null);
//     const [validationErrors, setValidationErrors] = useState({});
//     const [isChanged, setIsChanged] = useState(false); // Track changes

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = [...formData];
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//         setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         const updatedFormData = [...formData, newSection];
//         setFormData(updatedFormData);
//         setOpenSectionIndex(formData.length);
//         setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = [...formData];
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//         setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//     };

//     const handleRemoveSection = (sectionIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex: null });
//         setModalIsOpen(true);
//     };

//     const handleRemoveItem = (sectionIndex, contentIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex });
//         setModalIsOpen(true);
//     };

//     const confirmDelete = async () => {
//         const { sectionIndex, contentIndex } = itemToDelete;
//         if (contentIndex === null) {
//             const section = formData[sectionIndex];
//             if (section.id) {
//                 try {
//                     await deleteRecord({}, `editOpportunityData/${section.id}`);
//                     toast.success('Section deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting section:', error);
//                     toast.error('Error deleting section');
//                     setModalIsOpen(false);
//                     return;
//                 }
//             }
//             const updatedFormData = formData.filter(
//                 (_, index) => index !== sectionIndex
//             );
//             setFormData(updatedFormData);
//             setOpenSectionIndex(null);
//             setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//         } else {
//             const updatedFormData = [...formData];
//             updatedFormData[sectionIndex].data.content = updatedFormData[
//                 sectionIndex
//             ].data.content.filter((_, index) => index !== contentIndex);
//             setFormData(updatedFormData);

//             // Update the server with the new section data
//             const { id, data } = updatedFormData[sectionIndex];
//             if (id) {
//                 try {
//                     await updateRecord({ id, data }, 'editOpportunityData');
//                     toast.success('Item deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting item:', error);
//                     toast.error('Error deleting item');
//                 }
//             }
//             setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//         }
//         setModalIsOpen(false);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setSaving(true);
//         const errors = {};

//         try {
//             // Validate form data
//             formData.forEach((section, sectionIndex) => {
//                 if (!section.data.title.trim()) {
//                     if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                     errors[sectionIndex].title = 'Section title is required';
//                 }
//                 section.data.content.forEach((item, contentIndex) => {
//                     if (!item.label.trim()) {
//                         if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                         if (!errors[sectionIndex].content)
//                             errors[sectionIndex].content = {};
//                         if (!errors[sectionIndex].content[contentIndex])
//                             errors[sectionIndex].content[contentIndex] = {};
//                         errors[sectionIndex].content[contentIndex].label =
//                             'Item label is required';
//                     }
//                     if (!item.actionKeyword.trim()) {
//                         if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                         if (!errors[sectionIndex].content)
//                             errors[sectionIndex].content = {};
//                         if (!errors[sectionIndex].content[contentIndex])
//                             errors[sectionIndex].content[contentIndex] = {};
//                         errors[sectionIndex].content[
//                             contentIndex
//                         ].actionKeyword = 'Action keyword is required';
//                     }
//                 });
//             });

//             if (Object.keys(errors).length > 0) {
//                 throw new Error('Validation failed');
//             }

//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }

//             toast.success('Data updated successfully');
//             setValidationErrors({});
//             setOpenSectionIndex(null); // Close the currently open section
//             setInitialFormData([...formData]); // Update initial form data to current form data
//             setIsChanged(false); // Reset change detection
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error(error.message || 'Error updating data');
//             setValidationErrors(errors);

//             // Automatically open sections with validation errors
//             const firstErrorSectionIndex = parseInt(Object.keys(errors)[0], 10);
//             setOpenSectionIndex(firstErrorSectionIndex);
//         } finally {
//             setSaving(false);
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         setFormData(data.data);
//                         setInitialFormData(data.data); // Set initial form data
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <ClipLoader size={50} color="#123abc" loading={loading} />
//             </div>
//         );
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="relative p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Opportunity</h1>
//             <form onSubmit={handleSubmit} className="pb-20">
//                 {formData.map((section, sectionIndex) => (
//                     <div key={section.id || sectionIndex} className="mb-6">
//                         <button
//                             type="button"
//                             className={`flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg ${
//                                 validationErrors[sectionIndex]?.title
//                                     ? 'border-red-500'
//                                     : ''
//                             }`}
//                             onClick={() => toggleSection(sectionIndex)}
//                         >
//                             <span>{section.data.title}</span>
//                             <span
//                                 className={`transform transition-transform duration-300 ${
//                                     openSectionIndex === sectionIndex
//                                         ? 'rotate-180'
//                                         : 'rotate-0'
//                                 }`}
//                             >
//                                 <ChevronUpIcon className="w-5 h-5" />
//                             </span>
//                         </button>
//                         {openSectionIndex === sectionIndex && (
//                             <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                 <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                     <label className="block text-sm font-medium text-gray-700 w-full">
//                                         Title:
//                                         <input
//                                             type="text"
//                                             value={section.data.title}
//                                             onChange={(e) =>
//                                                 handleInputChange(
//                                                     sectionIndex,
//                                                     null,
//                                                     'title',
//                                                     e.target.value
//                                                 )
//                                             }
//                                             className={`mt-1 block w-full px-3 py-2 border ${
//                                                 validationErrors[sectionIndex]
//                                                     ?.title
//                                                     ? 'border-red-500'
//                                                     : 'border-gray-300'
//                                             } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                             required
//                                         />
//                                         {validationErrors[sectionIndex]
//                                             ?.title && (
//                                             <p className="text-red-500 text-sm mt-1">
//                                                 {
//                                                     validationErrors[
//                                                         sectionIndex
//                                                     ].title
//                                                 }
//                                             </p>
//                                         )}
//                                     </label>
//                                     <button
//                                         type="button"
//                                         className="ml-4 text-red-600 hover:text-red-900"
//                                         onClick={() =>
//                                             handleRemoveSection(sectionIndex)
//                                         }
//                                     >
//                                         <TrashIcon className="w-5 h-5" />
//                                     </button>
//                                 </div>
//                                 {section.data.content.map(
//                                     (item, contentIndex) => (
//                                         <div
//                                             key={contentIndex}
//                                             className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                         >
//                                             <div className="w-full">
//                                                 <label className="block text-sm font-medium text-gray-700">
//                                                     Label:
//                                                     <input
//                                                         type="text"
//                                                         value={item.label}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'label',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className={`mt-1 block w-full px-3 py-2 border ${
//                                                             validationErrors[
//                                                                 sectionIndex
//                                                             ]?.content?.[
//                                                                 contentIndex
//                                                             ]?.label
//                                                                 ? 'border-red-500'
//                                                                 : 'border-gray-300'
//                                                         } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                         required
//                                                     />
//                                                     {validationErrors[
//                                                         sectionIndex
//                                                     ]?.content?.[contentIndex]
//                                                         ?.label && (
//                                                         <p className="text-red-500 text-sm mt-1">
//                                                             {
//                                                                 validationErrors[
//                                                                     sectionIndex
//                                                                 ]?.content?.[
//                                                                     contentIndex
//                                                                 ]?.label
//                                                             }
//                                                         </p>
//                                                     )}
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     URL:
//                                                     <input
//                                                         type="text"
//                                                         value={item.url}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'url',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         disabled
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     Action Keyword:
//                                                     <input
//                                                         type="text"
//                                                         value={
//                                                             item.actionKeyword
//                                                         }
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'actionKeyword',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className={`mt-1 block w-full px-3 py-2 border ${
//                                                             validationErrors[
//                                                                 sectionIndex
//                                                             ]?.content?.[
//                                                                 contentIndex
//                                                             ]?.actionKeyword
//                                                                 ? 'border-red-500'
//                                                                 : 'border-gray-300'
//                                                         } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                         required
//                                                     />
//                                                     {validationErrors[
//                                                         sectionIndex
//                                                     ]?.content?.[contentIndex]
//                                                         ?.actionKeyword && (
//                                                         <p className="text-red-500 text-sm mt-1">
//                                                             {
//                                                                 validationErrors[
//                                                                     sectionIndex
//                                                                 ]?.content?.[
//                                                                     contentIndex
//                                                                 ]?.actionKeyword
//                                                             }
//                                                         </p>
//                                                     )}
//                                                 </label>
//                                             </div>
//                                             <button
//                                                 type="button"
//                                                 className="ml-4 text-red-600 hover:text-red-900"
//                                                 onClick={() =>
//                                                     handleRemoveItem(
//                                                         sectionIndex,
//                                                         contentIndex
//                                                     )
//                                                 }
//                                             >
//                                                 <TrashIcon className="w-5 h-5" />
//                                             </button>
//                                         </div>
//                                     )
//                                 )}
//                                 <button
//                                     type="button"
//                                     className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                     onClick={() => handleAddItem(sectionIndex)}
//                                 >
//                                     Add Item
//                                     <PlusIcon className="w-5 h-5 ml-2" />
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 ))}
//                 <button
//                     type="button"
//                     className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//             </form>
//             <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-end">
//                 <button
//                     type="submit"
//                     className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
//                         saving || !isChanged
//                             ? 'bg-gray-600'
//                             : 'bg-indigo-600 hover:bg-indigo-700'
//                     } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
//                     disabled={saving || !isChanged}
//                     onClick={handleSubmit}
//                 >
//                     {saving ? 'Saving...' : 'Save Changes'}
//                 </button>
//             </div>
//             <Modal
//                 isOpen={modalIsOpen}
//                 onRequestClose={() => setModalIsOpen(false)}
//                 contentLabel="Confirmation Modal"
//                 className="fixed inset-0 flex items-center justify-center"
//                 overlayClassName="fixed inset-0 bg-black bg-opacity-50"
//             >
//                 <div className="bg-white p-6 rounded shadow-lg text-center">
//                     <h2 className="text-xl font-semibold mb-4">
//                         Confirm Deletion
//                     </h2>
//                     <p className="mb-4">
//                         Are you sure you want to delete this item?
//                     </p>
//                     <button
//                         className="bg-red-600 text-white py-2 px-4 rounded mr-2"
//                         onClick={confirmDelete}
//                     >
//                         Yes
//                     </button>
//                     <button
//                         className="bg-gray-300 py-2 px-4 rounded"
//                         onClick={() => setModalIsOpen(false)}
//                     >
//                         No
//                     </button>
//                 </div>
//             </Modal>
//         </div>
//     );
// };

// add validation to action keyword as well 2222

// import React, { useState, useEffect } from 'react';
// import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
// import {
//     readRecord,
//     updateRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';
// import Modal from 'react-modal';
// import { ClipLoader } from 'react-spinners'; // Import a spinner loader
// import isEqual from 'lodash.isequal'; // Import lodash's isEqual for deep comparison

// Modal.setAppElement('#root'); // This is important for accessibility

// export const EditOpportunity = () => {
//     const [formData, setFormData] = useState([]);
//     const [initialFormData, setInitialFormData] = useState([]); // Store initial form data
//     const [openSectionIndex, setOpenSectionIndex] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [saving, setSaving] = useState(false);
//     const [error, setError] = useState(null);
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const [itemToDelete, setItemToDelete] = useState(null);
//     const [validationErrors, setValidationErrors] = useState({});
//     const [isChanged, setIsChanged] = useState(false); // Track changes

//     const deepClone = (obj) => {
//         return JSON.parse(JSON.stringify(obj));
//     };

//     const handleInputChange = (sectionIndex, contentIndex, field, value) => {
//         const updatedFormData = deepClone(formData);
//         if (field === 'title') {
//             updatedFormData[sectionIndex].data.title = value;
//         } else {
//             updatedFormData[sectionIndex].data.content[contentIndex][field] =
//                 value;
//         }
//         setFormData(updatedFormData);
//         setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//     };

//     const handleAddSection = () => {
//         const newSection = {
//             id: null, // New sections will have null id until they are saved
//             data: {
//                 title: 'New Section',
//                 content: [{ label: '', url: '', actionKeyword: '' }],
//             },
//         };
//         const updatedFormData = [...formData, newSection];
//         setFormData(updatedFormData);
//         setOpenSectionIndex(formData.length);
//         setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//     };

//     const handleAddItem = (sectionIndex) => {
//         const updatedFormData = deepClone(formData);
//         updatedFormData[sectionIndex].data.content.push({
//             label: '',
//             url: '',
//             actionKeyword: '',
//         });
//         setFormData(updatedFormData);
//         setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//     };

//     const handleRemoveSection = (sectionIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex: null });
//         setModalIsOpen(true);
//     };

//     const handleRemoveItem = (sectionIndex, contentIndex) => {
//         setItemToDelete({ sectionIndex, contentIndex });
//         setModalIsOpen(true);
//     };

//     const confirmDelete = async () => {
//         const { sectionIndex, contentIndex } = itemToDelete;
//         let updatedFormData = deepClone(formData);
//         if (contentIndex === null) {
//             const section = formData[sectionIndex];
//             if (section.id) {
//                 try {
//                     await deleteRecord({}, `editOpportunityData/${section.id}`);
//                     toast.success('Section deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting section:', error);
//                     toast.error('Error deleting section');
//                     setModalIsOpen(false);
//                     return;
//                 }
//             }
//             updatedFormData = formData.filter(
//                 (_, index) => index !== sectionIndex
//             );
//             setFormData(updatedFormData);
//             setOpenSectionIndex(null);
//             setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//         } else {
//             updatedFormData[sectionIndex].data.content = updatedFormData[
//                 sectionIndex
//             ].data.content.filter((_, index) => index !== contentIndex);
//             setFormData(updatedFormData);

//             // Update the server with the new section data
//             const { id, data } = updatedFormData[sectionIndex];
//             if (id) {
//                 try {
//                     await updateRecord({ id, data }, 'editOpportunityData');
//                     toast.success('Item deleted successfully');
//                 } catch (error) {
//                     console.error('Error deleting item:', error);
//                     toast.error('Error deleting item');
//                 }
//             }
//             setIsChanged(!isEqual(updatedFormData, initialFormData)); // Check for changes
//         }
//         setModalIsOpen(false);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setSaving(true);
//         const errors = {};

//         try {
//             // Validate form data
//             formData.forEach((section, sectionIndex) => {
//                 if (!section.data.title.trim()) {
//                     if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                     errors[sectionIndex].title = 'Section title is required';
//                 }
//                 section.data.content.forEach((item, contentIndex) => {
//                     if (!item.label.trim()) {
//                         if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                         if (!errors[sectionIndex].content)
//                             errors[sectionIndex].content = {};
//                         if (!errors[sectionIndex].content[contentIndex])
//                             errors[sectionIndex].content[contentIndex] = {};
//                         errors[sectionIndex].content[contentIndex].label =
//                             'Item label is required';
//                     }
//                     if (!item.actionKeyword.trim()) {
//                         if (!errors[sectionIndex]) errors[sectionIndex] = {};
//                         if (!errors[sectionIndex].content)
//                             errors[sectionIndex].content = {};
//                         if (!errors[sectionIndex].content[contentIndex])
//                             errors[sectionIndex].content[contentIndex] = {};
//                         errors[sectionIndex].content[
//                             contentIndex
//                         ].actionKeyword = 'Action keyword is required';
//                     }
//                 });
//             });

//             if (Object.keys(errors).length > 0) {
//                 throw new Error('   Some fil');
//             }

//             // Loop through each section and send an update request for each
//             for (const section of formData) {
//                 const { id, data } = section;
//                 await updateRecord({ id, data }, 'editOpportunityData');
//             }

//             toast.success('Data updated successfully');
//             setValidationErrors({});
//             setOpenSectionIndex(null); // Close the currently open section
//             setInitialFormData(deepClone(formData)); // Update initial form data to current form data
//             setIsChanged(false); // Reset change detection
//         } catch (error) {
//             console.error('Error updating data:', error);
//             toast.error(error.message || 'Error updating data');
//             setValidationErrors(errors);

//             // Automatically open sections with validation errors
//             const firstErrorSectionIndex = parseInt(Object.keys(errors)[0], 10);
//             setOpenSectionIndex(firstErrorSectionIndex);
//         } finally {
//             setSaving(false);
//         }
//     };

//     const toggleSection = (index) => {
//         setOpenSectionIndex(openSectionIndex === index ? null : index);
//     };

//     useEffect(() => {
//         let listen = true;
//         if (listen) {
//             (async () => {
//                 try {
//                     let data = await readRecord({}, 'editOpportunityData');
//                     if (data.status) {
//                         const clonedData = deepClone(data.data);
//                         setFormData(clonedData);
//                         setInitialFormData(clonedData); // Set initial form data
//                         setLoading(false);
//                     } else {
//                         setError(data.message);
//                         setLoading(false);
//                     }
//                 } catch (error) {
//                     setError('Error fetching data');
//                     setLoading(false);
//                 }
//             })();
//         }
//         return () => {
//             listen = false;
//         };
//     }, []);

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <ClipLoader size={50} color="#123abc" loading={loading} />
//             </div>
//         );
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     return (
//         <div className="relative p-6 bg-white rounded-lg shadow-md">
//             <h1 className="text-2xl font-bold mb-4">Edit Trending Course</h1>
//             <form onSubmit={handleSubmit} className="pb-20">
//                 {formData.map((section, sectionIndex) => (
//                     <div key={section.id || sectionIndex} className="mb-6">
//                         <button
//                             type="button"
//                             className={`flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg ${
//                                 validationErrors[sectionIndex]?.title
//                                     ? 'border-red-500'
//                                     : ''
//                             }`}
//                             onClick={() => toggleSection(sectionIndex)}
//                         >
//                             <span>{section.data.title}</span>
//                             <span
//                                 className={`transform transition-transform duration-300 ${
//                                     openSectionIndex === sectionIndex
//                                         ? 'rotate-180'
//                                         : 'rotate-0'
//                                 }`}
//                             >
//                                 <ChevronUpIcon className="w-5 h-5" />
//                             </span>
//                         </button>
//                         {openSectionIndex === sectionIndex && (
//                             <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
//                                 <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
//                                     <label className="block text-sm font-medium text-gray-700 w-full">
//                                         Title
//                                         <span className="text-red-500">*</span>
//                                         :
//                                         <input
//                                             type="text"
//                                             value={section.data.title}
//                                             onChange={(e) =>
//                                                 handleInputChange(
//                                                     sectionIndex,
//                                                     null,
//                                                     'title',
//                                                     e.target.value
//                                                 )
//                                             }
//                                             className={`mt-1 block w-full px-3 py-2 border ${
//                                                 validationErrors[sectionIndex]
//                                                     ?.title
//                                                     ? 'border-red-500'
//                                                     : 'border-gray-300'
//                                             } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                             required
//                                         />
//                                         {validationErrors[sectionIndex]
//                                             ?.title && (
//                                             <p className="text-red-500 text-sm mt-1">
//                                                 {
//                                                     validationErrors[
//                                                         sectionIndex
//                                                     ].title
//                                                 }
//                                             </p>
//                                         )}
//                                     </label>
//                                     <button
//                                         type="button"
//                                         className="ml-4 text-red-600 hover:text-red-900"
//                                         onClick={() =>
//                                             handleRemoveSection(sectionIndex)
//                                         }
//                                     >
//                                         <TrashIcon className="w-5 h-5" />
//                                     </button>
//                                 </div>
//                                 {section.data.content.map(
//                                     (item, contentIndex) => (
//                                         <div
//                                             key={contentIndex}
//                                             className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
//                                         >
//                                             <div className="w-full">
//                                                 <label className="block text-sm font-medium text-gray-700">
//                                                     Label
//                                                     <span className="text-red-500">
//                                                         *
//                                                     </span>
//                                                     :
//                                                     <input
//                                                         type="text"
//                                                         value={item.label}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'label',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className={`mt-1 block w-full px-3 py-2 border ${
//                                                             validationErrors[
//                                                                 sectionIndex
//                                                             ]?.content?.[
//                                                                 contentIndex
//                                                             ]?.label
//                                                                 ? 'border-red-500'
//                                                                 : 'border-gray-300'
//                                                         } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                         required
//                                                     />
//                                                     {validationErrors[
//                                                         sectionIndex
//                                                     ]?.content?.[contentIndex]
//                                                         ?.label && (
//                                                         <p className="text-red-500 text-sm mt-1">
//                                                             {
//                                                                 validationErrors[
//                                                                     sectionIndex
//                                                                 ]?.content?.[
//                                                                     contentIndex
//                                                                 ]?.label
//                                                             }
//                                                         </p>
//                                                     )}
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     URL:
//                                                     <input
//                                                         type="text"
//                                                         value={item.url}
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'url',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                                                         disabled
//                                                     />
//                                                 </label>
//                                                 <label className="block text-sm font-medium text-gray-700 mt-3">
//                                                     Action Keyword
//                                                     <span>
//                                                         (Search keyword)
//                                                         <span className="text-red-500">
//                                                             *
//                                                         </span>
//                                                         :
//                                                     </span>
//                                                     <input
//                                                         type="text"
//                                                         value={
//                                                             item.actionKeyword
//                                                         }
//                                                         onChange={(e) =>
//                                                             handleInputChange(
//                                                                 sectionIndex,
//                                                                 contentIndex,
//                                                                 'actionKeyword',
//                                                                 e.target.value
//                                                             )
//                                                         }
//                                                         className={`mt-1 block w-full px-3 py-2 border ${
//                                                             validationErrors[
//                                                                 sectionIndex
//                                                             ]?.content?.[
//                                                                 contentIndex
//                                                             ]?.actionKeyword
//                                                                 ? 'border-red-500'
//                                                                 : 'border-gray-300'
//                                                         } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
//                                                         required
//                                                     />
//                                                     {validationErrors[
//                                                         sectionIndex
//                                                     ]?.content?.[contentIndex]
//                                                         ?.actionKeyword && (
//                                                         <p className="text-red-500 text-sm mt-1">
//                                                             {
//                                                                 validationErrors[
//                                                                     sectionIndex
//                                                                 ]?.content?.[
//                                                                     contentIndex
//                                                                 ]?.actionKeyword
//                                                             }
//                                                         </p>
//                                                     )}
//                                                 </label>
//                                             </div>
//                                             <button
//                                                 type="button"
//                                                 className="ml-4 text-red-600 hover:text-red-900"
//                                                 onClick={() =>
//                                                     handleRemoveItem(
//                                                         sectionIndex,
//                                                         contentIndex
//                                                     )
//                                                 }
//                                             >
//                                                 <TrashIcon className="w-5 h-5" />
//                                             </button>
//                                         </div>
//                                     )
//                                 )}
//                                 <button
//                                     type="button"
//                                     className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
//                                     onClick={() => handleAddItem(sectionIndex)}
//                                 >
//                                     Add Item
//                                     <PlusIcon className="w-5 h-5 ml-2" />
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                 ))}
//                 <button
//                     type="button"
//                     className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
//                     onClick={handleAddSection}
//                 >
//                     Add Section
//                     <PlusIcon className="w-5 h-5 ml-2" />
//                 </button>
//             </form>
//             <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-end">
//                 <button
//                     type="submit"
//                     className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
//                         saving || !isChanged
//                             ? 'bg-gray-600'
//                             : 'bg-indigo-600 hover:bg-indigo-700'
//                     } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
//                     disabled={saving || !isChanged}
//                     onClick={handleSubmit}
//                 >
//                     {saving ? 'Saving...' : 'Save Changes'}
//                 </button>
//             </div>
//             <Modal
//                 isOpen={modalIsOpen}
//                 onRequestClose={() => setModalIsOpen(false)}
//                 contentLabel="Confirmation Modal"
//                 className="fixed inset-0 flex items-center justify-center"
//                 overlayClassName="fixed inset-0 bg-black bg-opacity-50"
//             >
//                 <div className="bg-white p-6 rounded shadow-lg text-center">
//                     <h2 className="text-xl font-semibold mb-4">
//                         Confirm Deletion
//                     </h2>
//                     <p className="mb-4">
//                         Are you sure you want to delete this item?
//                     </p>
//                     <button
//                         className="bg-red-600 text-white py-2 px-4 rounded mr-2"
//                         onClick={confirmDelete}
//                     >
//                         Yes
//                     </button>
//                     <button
//                         className="bg-gray-300 py-2 px-4 rounded"
//                         onClick={() => setModalIsOpen(false)}
//                     >
//                         No
//                     </button>
//                 </div>
//             </Modal>
//         </div>
//     );
// };

import React, { useState, useEffect } from 'react';
import { ChevronUpIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
import {
    readRecord,
    updateRecord,
    deleteRecord,
} from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import isEqual from 'lodash.isequal';

Modal.setAppElement('#root');

export const EditOpportunity = () => {
    const [formData, setFormData] = useState([]);
    const [initialFormData, setInitialFormData] = useState([]);
    const [openSectionIndex, setOpenSectionIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [deleteAll, setDeleteAll] = useState(false);

    const deepClone = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    const handleInputChange = (sectionIndex, contentIndex, field, value) => {
        const updatedFormData = deepClone(formData);
        if (field === 'title') {
            updatedFormData[sectionIndex].data.title = value;
        } else {
            updatedFormData[sectionIndex].data.content[contentIndex][field] =
                value;
        }
        setFormData(updatedFormData);
        setIsChanged(!isEqual(updatedFormData, initialFormData));
    };

    const handleAddSection = () => {
        const newSection = {
            id: null,
            data: {
                title: 'New Section',
                content: [{ label: '', url: '', actionKeyword: '' }],
            },
        };
        const updatedFormData = [...formData, newSection];
        setFormData(updatedFormData);
        setOpenSectionIndex(formData.length);
        setIsChanged(!isEqual(updatedFormData, initialFormData));
    };

    const handleAddItem = (sectionIndex) => {
        const updatedFormData = deepClone(formData);
        updatedFormData[sectionIndex].data.content.push({
            label: '',
            url: '',
            actionKeyword: '',
        });
        setFormData(updatedFormData);
        setIsChanged(!isEqual(updatedFormData, initialFormData));
    };

    const handleRemoveSection = (sectionIndex) => {
        setItemToDelete({ sectionIndex, contentIndex: null });
        setModalIsOpen(true);
    };

    const handleRemoveItem = (sectionIndex, contentIndex) => {
        setItemToDelete({ sectionIndex, contentIndex });
        setModalIsOpen(true);
    };

    const confirmDelete = async () => {
        const { sectionIndex, contentIndex } = itemToDelete;
        let updatedFormData = deepClone(formData);

        if (deleteAll) {
            try {
                for (const section of formData) {
                    if (section.id) {
                        await deleteRecord(
                            {},
                            `editOpportunityData/${section.id}`
                        );
                    }
                }
                toast.success('All sections deleted successfully');
            } catch (error) {
                console.error('Error deleting sections:', error);
                toast.error('Error deleting sections');
                setModalIsOpen(false);
                setDeleteAll(false);
                return;
            }
            updatedFormData = [];
            setFormData(updatedFormData);
            setOpenSectionIndex(null);
            setIsChanged(!isEqual(updatedFormData, initialFormData));
            setDeleteAll(false);
        } else {
            if (contentIndex === null) {
                const section = formData[sectionIndex];
                if (section.id) {
                    try {
                        await deleteRecord(
                            {},
                            `editOpportunityData/${section.id}`
                        );
                        toast.success('Section deleted successfully');
                    } catch (error) {
                        console.error('Error deleting section:', error);
                        toast.error('Error deleting section');
                        setModalIsOpen(false);
                        return;
                    }
                }
                updatedFormData = formData.filter(
                    (_, index) => index !== sectionIndex
                );
                setFormData(updatedFormData);
                setOpenSectionIndex(null);
                setIsChanged(!isEqual(updatedFormData, initialFormData));
            } else {
                updatedFormData[sectionIndex].data.content = updatedFormData[
                    sectionIndex
                ].data.content.filter((_, index) => index !== contentIndex);
                setFormData(updatedFormData);

                const { id, data } = updatedFormData[sectionIndex];
                if (id) {
                    try {
                        await updateRecord({ id, data }, 'editOpportunityData');
                        toast.success('Item deleted successfully');
                    } catch (error) {
                        console.error('Error deleting item:', error);
                        toast.error('Error deleting item');
                    }
                }
                setIsChanged(!isEqual(updatedFormData, initialFormData));
            }
        }
        setModalIsOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        const errors = {};

        try {
            formData.forEach((section, sectionIndex) => {
                if (!section.data.title.trim()) {
                    if (!errors[sectionIndex]) errors[sectionIndex] = {};
                    errors[sectionIndex].title = 'Section title is required';
                }
                section.data.content.forEach((item, contentIndex) => {
                    if (!item.label.trim()) {
                        if (!errors[sectionIndex]) errors[sectionIndex] = {};
                        if (!errors[sectionIndex].content)
                            errors[sectionIndex].content = {};
                        if (!errors[sectionIndex].content[contentIndex])
                            errors[sectionIndex].content[contentIndex] = {};
                        errors[sectionIndex].content[contentIndex].label =
                            'Item label is required';
                    }
                    if (!item.actionKeyword.trim()) {
                        if (!errors[sectionIndex]) errors[sectionIndex] = {};
                        if (!errors[sectionIndex].content)
                            errors[sectionIndex].content = {};
                        if (!errors[sectionIndex].content[contentIndex])
                            errors[sectionIndex].content[contentIndex] = {};
                        errors[sectionIndex].content[
                            contentIndex
                        ].actionKeyword = 'Action keyword is required';
                    }
                });
            });

            if (Object.keys(errors).length > 0) {
                throw new Error('Fill all fields');
            }

            for (const section of formData) {
                const { id, data } = section;
                await updateRecord({ id, data }, 'editOpportunityData');
            }

            toast.success('Data updated successfully');
            setValidationErrors({});
            setOpenSectionIndex(null);
            setInitialFormData(deepClone(formData));
            setIsChanged(false);
        } catch (error) {
            console.error('Error updating data:', error);
            toast.error(error.message || 'Error updating data');
            setValidationErrors(errors);

            const firstErrorSectionIndex = parseInt(Object.keys(errors)[0], 10);
            setOpenSectionIndex(firstErrorSectionIndex);
        } finally {
            setSaving(false);
        }
    };

    const toggleSection = (index) => {
        setOpenSectionIndex(openSectionIndex === index ? null : index);
    };

    useEffect(() => {
        let listen = true;
        if (listen) {
            (async () => {
                try {
                    let data = await readRecord({}, 'editOpportunityData');
                    if (data.status) {
                        const clonedData = deepClone(data.data);
                        setFormData(clonedData);
                        setInitialFormData(clonedData);
                        setLoading(false);
                    } else {
                        setError(data.message);
                        setLoading(false);
                    }
                } catch (error) {
                    setError('Error fetching data');
                    setLoading(false);
                }
            })();
        }
        return () => {
            listen = false;
        };
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader size={50} color="#123abc" loading={loading} />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="relative p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-4">Edit Trending Course</h1>
            <form onSubmit={handleSubmit} className="pb-20">
                {formData.map((section, sectionIndex) => (
                    <div key={section.id || sectionIndex} className="mb-6">
                        <button
                            type="button"
                            className={`flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm rounded-lg ${
                                validationErrors[sectionIndex]?.title
                                    ? 'border-red-500'
                                    : ''
                            }`}
                            onClick={() => toggleSection(sectionIndex)}
                        >
                            <span>{section.data.title}</span>
                            <span
                                className={`transform transition-transform duration-300 ${
                                    openSectionIndex === sectionIndex
                                        ? 'rotate-180'
                                        : 'rotate-0'
                                }`}
                            >
                                <ChevronUpIcon className="w-5 h-5" />
                            </span>
                        </button>
                        {openSectionIndex === sectionIndex && (
                            <div className="p-4 border border-t-0 border-gray-200 rounded-b-lg">
                                <div className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center">
                                    <label className="block text-sm font-medium text-gray-700 w-full">
                                        Title
                                        <span className="text-red-500">*</span>
                                        :
                                        <input
                                            type="text"
                                            value={section.data.title}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    sectionIndex,
                                                    null,
                                                    'title',
                                                    e.target.value
                                                )
                                            }
                                            className={`mt-1 block w-full px-3 py-2 border ${
                                                validationErrors[sectionIndex]
                                                    ?.title
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                            required
                                        />
                                        {validationErrors[sectionIndex]
                                            ?.title && (
                                            <p className="text-red-500 text-sm mt-1">
                                                {
                                                    validationErrors[
                                                        sectionIndex
                                                    ].title
                                                }
                                            </p>
                                        )}
                                    </label>
                                    <button
                                        type="button"
                                        className="ml-4 text-red-600 hover:text-red-900"
                                        onClick={() =>
                                            handleRemoveSection(sectionIndex)
                                        }
                                    >
                                        <TrashIcon className="w-5 h-5" />
                                    </button>
                                </div>
                                {section.data.content.map(
                                    (item, contentIndex) => (
                                        <div
                                            key={contentIndex}
                                            className="mb-4 p-4 border-b border-gray-200 flex justify-between items-center"
                                        >
                                            <div className="w-full">
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Label
                                                    <span className="text-red-500">
                                                        *
                                                    </span>
                                                    :
                                                    <input
                                                        type="text"
                                                        value={item.label}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                sectionIndex,
                                                                contentIndex,
                                                                'label',
                                                                e.target.value
                                                            )
                                                        }
                                                        className={`mt-1 block w-full px-3 py-2 border ${
                                                            validationErrors[
                                                                sectionIndex
                                                            ]?.content?.[
                                                                contentIndex
                                                            ]?.label
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                                        required
                                                    />
                                                    {validationErrors[
                                                        sectionIndex
                                                    ]?.content?.[contentIndex]
                                                        ?.label && (
                                                        <p className="text-red-500 text-sm mt-1">
                                                            {
                                                                validationErrors[
                                                                    sectionIndex
                                                                ]?.content?.[
                                                                    contentIndex
                                                                ]?.label
                                                            }
                                                        </p>
                                                    )}
                                                </label>
                                                <label className="block text-sm font-medium text-gray-700 mt-3">
                                                    URL:
                                                    <input
                                                        type="text"
                                                        value={item.url}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                sectionIndex,
                                                                contentIndex,
                                                                'url',
                                                                e.target.value
                                                            )
                                                        }
                                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                        disabled
                                                    />
                                                </label>
                                                <label className="block text-sm font-medium text-gray-700 mt-3">
                                                    Action Keyword
                                                    <span>
                                                        (Search keyword)
                                                        <span className="text-red-500">
                                                            *
                                                        </span>
                                                        :
                                                    </span>
                                                    <input
                                                        type="text"
                                                        value={
                                                            item.actionKeyword
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                sectionIndex,
                                                                contentIndex,
                                                                'actionKeyword',
                                                                e.target.value
                                                            )
                                                        }
                                                        className={`mt-1 block w-full px-3 py-2 border ${
                                                            validationErrors[
                                                                sectionIndex
                                                            ]?.content?.[
                                                                contentIndex
                                                            ]?.actionKeyword
                                                                ? 'border-red-500'
                                                                : 'border-gray-300'
                                                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                                        required
                                                    />
                                                    {validationErrors[
                                                        sectionIndex
                                                    ]?.content?.[contentIndex]
                                                        ?.actionKeyword && (
                                                        <p className="text-red-500 text-sm mt-1">
                                                            {
                                                                validationErrors[
                                                                    sectionIndex
                                                                ]?.content?.[
                                                                    contentIndex
                                                                ]?.actionKeyword
                                                            }
                                                        </p>
                                                    )}
                                                </label>
                                            </div>
                                            <button
                                                type="button"
                                                className="ml-4 text-red-600 hover:text-red-900"
                                                onClick={() =>
                                                    handleRemoveItem(
                                                        sectionIndex,
                                                        contentIndex
                                                    )
                                                }
                                            >
                                                <TrashIcon className="w-5 h-5" />
                                            </button>
                                        </div>
                                    )
                                )}
                                <button
                                    type="button"
                                    className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                    onClick={() => handleAddItem(sectionIndex)}
                                >
                                    Add Item
                                    <PlusIcon className="w-5 h-5 ml-2" />
                                </button>
                            </div>
                        )}
                    </div>
                ))}
                <button
                    type="button"
                    className="bg-[#E5ECFF] py-2 px-4 p-3 rounded-[2px] text-[#267DF8] text-[10px] font-[500] mr-6 inline-flex justify-center border border-black-2 border-transparent shadow-sm text-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={handleAddSection}
                >
                    Add Section
                    <PlusIcon className="w-5 h-5 ml-2" />
                </button>
                <button
                    type="button"
                    className="bg-red-600 text-white py-2 px-4 rounded mt-4"
                    onClick={() => {
                        setItemToDelete({
                            sectionIndex: null,
                            contentIndex: null,
                        });
                        setDeleteAll(true);
                        setModalIsOpen(true);
                    }}
                >
                    Delete All Sections
                </button>
            </form>
            <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md flex justify-end">
                <button
                    type="submit"
                    className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                        saving || !isChanged
                            ? 'bg-gray-600'
                            : 'bg-indigo-600 hover:bg-indigo-700'
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    disabled={saving || !isChanged}
                    onClick={handleSubmit}
                >
                    {saving ? 'Saving...' : 'Save Changes'}
                </button>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Confirmation Modal"
                className="fixed inset-0 flex items-center justify-center"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                <div className="bg-white p-6 rounded shadow-lg text-center">
                    <h2 className="text-xl font-semibold mb-4">
                        Confirm Deletion
                    </h2>
                    <p className="mb-4">
                        Are you sure you want to delete{' '}
                        {deleteAll ? 'all sections' : 'this item'}?
                    </p>
                    <button
                        className="bg-red-600 text-white py-2 px-4 rounded mr-2"
                        onClick={confirmDelete}
                    >
                        Yes
                    </button>
                    <button
                        className="bg-gray-300 py-2 px-4 rounded"
                        onClick={() => setModalIsOpen(false)}
                    >
                        No
                    </button>
                </div>
            </Modal>
        </div>
    );
};
