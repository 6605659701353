import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../..';
import { showAdvertisementModal } from '../../Redux/Reducers/popupReducers';
import closeBtn from '../../assets/Images/closeBtn.svg';
import Adupload from '../../assets/Images/Adupload.svg';
import { DragAndDrop } from './DragandDrop';
import { useDebounce } from '../../hooks/DebounceHook';
import toast, { ToastBar } from 'react-hot-toast';
import { setAdvertisement } from '../../Redux/Reducers/advertisementReducers';
import { readRecord } from '../../Services/backend/apiCalls';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const PopupForAdvertisement = ({ formik, forUidCallBack, checkFunc }: any) => {
    const { id }: any = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const [urlTypeText, setUrlTypeText] = useState<String>('');
    const [onFocus, setOnFocus] = useState(false);
    const [urlTypeIndex, setUrlTypeIndex] = useState<Number>(0);
    const [searchResponseAPI, setSearchResponseAPI] = useState([]);
    const [searchResponseData, setSearchResponseData] = useState([]);
    const [dropDown, setDropDown] = useState(false);
    // const [urlIn, setUrlIn] = useState('');
    // const [urlOut, setUrlOut] = useState('');
    const [{ token, role }, { advertisement }] = useAppSelector(
        ({ signin, getAd }: any) => [
            signin['signinValue'],
            getAd['AdvertisementData'],
        ]
    );
    // const [searchTerm, setSearchTerm] = useState(
    //     localStorage.getItem('searchInstitute') || ''
    // );
    // const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const forURL = ['Inside', 'Outside'];

    const closeModel = () => {
        localStorage.setItem('searchInstitute', '');
        dispatch(showAdvertisementModal(false));
        navigate(`/dashboard/advertisements`);
    };

    const editRequestReload = async () => {
        const getAdminAds = await readRecord({ id: id }, 'admin/advertisements');
        const { image, url, expiry_time, file_name, url_type } =
            getAdminAds.data[0];
        if (getAdminAds.status) {
            formik.setValues(
                {
                    url_type,
                    image,
                    // url: url.split('/')[0],
                    // expiry_time,
                    file_name,
                },
                true
            );
            if (url_type === 'inside') {
                formik.setFieldValue('url', url.split('/')[0]);
                formik.setFieldValue('url_Out', '');
            } else {
                formik.setFieldValue('url_Out', url);
                formik.setFieldValue('url', '');
                setUrlTypeIndex(1);
            }
            // url_type === 'outside' && setUrlTypeIndex(1);
        } else if (
            getAdminAds.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getAdminAds.message);
        } else toast.error(getAdminAds.message);
    };

    useEffect(() => {
        if (id) {
            editRequestReload();
        }
    }, []);

    const selectUrlType = (side: any, index: number) => {
        // formik.setFieldValue('url','')
        checkFunc(index);
        setOnFocus(false);
        setDropDown(false);
        console.log(side, index, 'selected');
        formik.setFieldValue('url_type', side.toLowerCase());
        setUrlTypeIndex(index);
    };

    const fetchOnChangeDetails = async (e: any) => {
        const { value } = e.target;
        if (value.length === 0) {
            setOnFocus(false);
            setDropDown(false);
        } else {
            setDropDown(true);
            const filterData = searchResponseAPI.filter((idx: any) =>
                idx['name'].toLowerCase().includes(value.toLowerCase())
            );
            setSearchResponseData([...filterData]);
        }
    };

    const searchResponseForURL = (val: any) => {
        setDropDown(false);
        forUidCallBack(val['user_id']);
        formik.setFieldValue('url', val['name']);
    };

    const setUrlForOutside = (e: any) => {
        const { value } = e.target;
        formik.setFieldValue('url_Out', value);
    };

    const fetchInstituteDetails = async () => {
        const response = await readRecord({}, 'admin/queryprofiles');
        if (response.status) {
            setSearchResponseAPI(response.data);
            setSearchResponseData(response.data);
        } else if (
            response.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(response.message);
        } else toast.error(response.message);
    };

    useEffect(() => {
        fetchInstituteDetails();
    }, []);

    return (
        <div className="h-[100%] w-full flex items-center justify-center bg-black bg-opacity-[0.64] absolute top-0 left-0 overflow-hidden z-[3]">
            <div className="w-1/2 lg:w-5/12 xl:w-4/12 2xl:w-3/12 mx-auto border-[1px] border-[#F9FAFB] bg-[#F6F7F8] rounded-[19px] py-4">
                <div className="w-full flex flex-col items-center space-y-7">
                    <div className="relative mt-4 w-11/12">
                        <h1 className="text-center text-[18px] xl:text-[22px] font-[600] mt-2">
                            {id ? 'Edit' : 'Add'} Advertisement
                        </h1>
                        <img
                            src={closeBtn}
                            alt="CloseButton"
                            className="w-[14px] absolute right-0 top-0 cursor-pointer"
                            onClick={closeModel}
                        />
                    </div>
                    <form
                        className="w-full flex flex-col items-center space-y-7"
                        onSubmit={formik.handleSubmit}
                    >
                        <DragAndDrop formik={formik} />
                        <div className="flex w-10/12 gap-2 mx-auto">
                            {forURL.map((side, index) => (
                                <p
                                    key={index}
                                    className={`text-[15px] font-[400] border-[2px] border-[#EFEFEF] p-1 px-2 cursor-pointer ${
                                        index === 0
                                            ? 'rounded-l-md'
                                            : 'rounded-r-md'
                                    } ${
                                        index === urlTypeIndex &&
                                        'bg-[#267DF8] text-white'
                                    }`}
                                    onClick={() => selectUrlType(side, index)}
                                >
                                    {side}
                                </p>
                            ))}
                        </div>
                        <div className="w-10/12 mx-auto relative">
                            {urlTypeIndex === 0 ? (
                                <>
                                    <div className="w-full flex border-[1px] border-[#EFEFEF]">
                                        <input
                                            name="url"
                                            value={formik.values.url}
                                            onChange={(e: any) => {
                                                formik.handleChange(e);
                                                fetchOnChangeDetails(e);
                                            }}
                                            onFocus={() => {
                                                setOnFocus(true);
                                                setDropDown(true);
                                            }}
                                            onBlur={formik.handleBlur}
                                            className="w-full py-1 rounded-[6px] px-2 text-[14px] font-[500]"
                                        />
                                    </div>
                                    {dropDown ? (
                                        <div className="w-full bg-gray-100 h-[75px] scroll-smooth overflow-y-scroll border-[1px] border-[#EFEFEF]">
                                            {searchResponseData &&
                                                searchResponseData.map(
                                                    (val: any, i: any) => (
                                                        <div
                                                            className="cursor-pointer hover:bg-slate-500"
                                                            key={i}
                                                            onClick={() =>
                                                                searchResponseForURL(
                                                                    val
                                                                )
                                                            }
                                                        >
                                                            <p
                                                                key={i}
                                                                className="p-1 px-4 text-[14px] font-[400] text-[#5D6370] hover:text-white"
                                                            >
                                                                {val.name}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            {formik.errors.url &&
                                                formik.touched.url && (
                                                    <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-26px] right-0">
                                                        {formik.errors.url}
                                                    </p>
                                                )}
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <div className="w-full flex relative border-[1px] border-[#EFEFEF]">
                                    <input
                                        name="url_Out"
                                        value={formik.values.url_Out}
                                        placeholder="Starts with protocol https:// or http://"
                                        onChange={(e: any) => {
                                            formik.handleChange(e);
                                            setUrlForOutside(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        className="w-full py-1 rounded-[6px] px-2 text-[14px] font-[500]"
                                    />
                                    {formik.errors.url_Out &&
                                        formik.touched.url_Out && (
                                            <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-26px] right-0">
                                                {formik.errors.url_Out}
                                            </p>
                                        )}
                                </div>
                            )}
                        </div>
                        <button
                            type="submit"
                            className="bg-[#267DF8] text-white text-[12px] font-[400] p-2 xl:p-3 px-5 xl:px-8 rounded-[8px] cursor-pointer"
                        >
                            {id ? 'Save' : 'Add'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};
