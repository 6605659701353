// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import Frame from '../../assets/Images/Frame.svg';

// export const DragDrop = ({ default: Component, onUpload }: any) => {
//     const {
//         getRootProps,
//         getInputProps,
//         isDragActive,
//         isDragAccept,
//         isDragReject,
//         acceptedFiles,
//     } = useDropzone({
//         accept: {
//             'image/*': [],
//         },
//         multiple: false,
//         maxFiles: 1,
//         // maxSize: 20000,
//         onDrop: (acceptedFiles: any) => {
//             onUpload(acceptedFiles);
//         },
//     });

//     return (
//         <div
//             className={`${isDragActive && 'border-green-600'}`}
//             {...getRootProps()}
//         >
//             <Component inputProps={getInputProps} />
//         </div>
//     );
// };

import React from 'react';
import { useDropzone } from 'react-dropzone';
import Frame from '../../assets/Images/Frame.svg';

export const DragDrop = ({ default: Component, onUpload }: any) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        accept: {
            'image/*': [],
        },
        multiple: false,
        maxFiles: 1,
        onDrop: (acceptedFiles: any) => {
            console.log('Files dropped:', acceptedFiles); // Debugging log
            onUpload(acceptedFiles);
        },
    });

    const rootProps = getRootProps();
    const inputProps = getInputProps();

    return (
        <div
            className={`${
                isDragActive ? 'border-green-600' : 'border-gray-400'
            } border-2 p-4`}
            {...rootProps}
        >
            <input {...inputProps} />
            <Component inputProps={inputProps} />
        </div>
    );
};
