import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { useNavigate, useParams } from 'react-router-dom';
import { showAllCouponsModal } from '../../Redux/Reducers/popupReducers';
import closeBtn from '../../assets/Images/closeBtn.svg';
import Adupload from '../../assets/Images/Adupload.svg';
import { dateFormat } from './Coupons';
import { readRecord } from '../../Services/backend/apiCalls';
import { toast } from 'react-hot-toast';
import { getTomorrowDate, getTodayDate } from '../Elements/TomorrowDate';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const PopupForCoupons = ({
    formik,
    onChangeInputValue,
    payDetails,
    chooseField,
    payDetailsRef
}: any) => {
    const { id }: any = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [{ token, role }, { coupons }] = useAppSelector(
        ({ signin, getCoupon }: any) => [
            signin['signinValue'],
            getCoupon['CouponsData'],
        ]
    );

    const editRequestReload = async () => {
        const getCouponsResponse = await readRecord({ id }, 'admin/coupons'); ////url  generate
        const {
            amount,
            code,
            description,
            discount,
            maximum_order_amount,
            end_date,
            start_date,
            title,
        } = getCouponsResponse.data[0];
        if (getCouponsResponse.status) {
            if (id) {
                formik.setValues(
                    {
                        ...(amount !== null && { amount }),
                        code,
                        description,
                        ...(discount !== null && { discount }),
                        ...(maximum_order_amount !== null && {
                            maximum_order_amount,
                        }),
                        end_date: end_date.split('T')[0],
                        start_date: start_date.split('T')[0],
                        title,
                    },
                    true
                );
                if (amount && (payDetails==='Amount')) {
                    chooseField('Amount');
                    formik.setFieldValue('discount', '');
                                        ((amount !== null)&&(maximum_order_amount!==null)&&
                                             formik.setFieldValue(
                                                'amount',
                                                amount
                                            ) &&
                                        formik.setFieldValue(
                                            'maximum_order_amount',
                                            maximum_order_amount
                                        ));
                } else if (discount && payDetails === 'Discount') {
                    chooseField('Discount');
                    formik.setFieldValue('amount', '');
                    formik.setFieldValue('maximum_order_amount', '');
                    discount !== null &&
                        formik.setFieldValue('discount', discount);
                }
                // if (payDetails === 'Amount') {
                //     formik.setFieldValue('discount', '');
                // }
            }
        } else if (
            getCouponsResponse.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getCouponsResponse.message);
        } else toast.error(getCouponsResponse.message);
    };

    // const keyObject: any = Object.keys(formik.errors).length;
    // console.log(keyObject, 'keyObject');

    useEffect(() => {
        if (id) {
            editRequestReload();
        }
    }, [chooseField]);

    const closeModel = () => {
        dispatch(showAllCouponsModal(false));
        navigate(`/dashboard/coupons`);
    };

    return (
        <div className="h-[100%] w-full flex items-center justify-center bg-black bg-opacity-[0.64] absolute top-0 left-0 overflow-hidden z-[3]">
            <div className="w-3/5 xl:w-1/2 2xl:w-2/5 mx-auto border-[1px] border-[#F9FAFB] bg-[#F6F7F8] rounded-[19px] py-3">
                <div className="w-full flex flex-col items-center space-y-2">
                    <div className="relative mt-4 w-11/12">
                        <h1 className="text-center text-[18px] xl:text-[22px] font-[600] mt-2">
                            {id ? 'Edit' : 'Add'} Coupon
                        </h1>
                        <img
                            src={closeBtn}
                            alt="CloseButton"
                            className="w-[14px] absolute right-0 top-0 cursor-pointer"
                            onClick={closeModel}
                        />
                    </div>
                    <form
                        className="space-y-8 items-center w-11/12 flex flex-col"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="space-y-1 w-full">
                            <InputTag
                                name="title"
                                label={'Title'}
                                type={'text'}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.title}
                                isTouched={formik.touched.title}
                            />
                            <Textarea
                                name="description"
                                label={'Description'}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.description}
                                isTouched={formik.touched.description}
                            />
                            <div className="flex justify-between gap-5">
                                <div className="space-y-1 w-full">
                                    <label className="text-[15px] font-[500] text-[#9C9C9C]">
                                        Choose
                                    </label>
                                    <div className="flex items-center gap-2 w-full">
                                        <RadioType
                                            name="type"
                                            label={'Amount'}
                                            type={'radio'}
                                            checked={payDetails === 'Amount'}
                                            onChange={(event: any) => {
                                                onChangeInputValue(
                                                    event,
                                                    'Amount'
                                                );
                                            }}
                                        />
                                        <RadioType
                                            name="type"
                                            label={'Discount'}
                                            type={'radio'}
                                            checked={payDetails === 'Discount'}
                                            onChange={(event: any) => {
                                                onChangeInputValue(
                                                    event,
                                                    'Discount'
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                {payDetails === 'Amount' ? (
                                    <InputTag
                                        name="amount"
                                        label={`${
                                            payDetails === 'Amount' && 'Amount'
                                        }`}
                                        type={'number'}
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.amount}
                                        isTouched={formik.touched.amount}
                                    />
                                ) : (
                                    <InputTag
                                        name="discount"
                                        label={`${
                                            payDetails === 'Discount' &&
                                            'Discount'
                                        }`}
                                        type={'number'}
                                        value={formik.values.discount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.errors.discount}
                                        isTouched={formik.touched.discount}
                                    />
                                )}
                            </div>
                            <div className="flex justify-between gap-5">
                                <InputTag
                                    name="code"
                                    label={'Coupon Code'}
                                    type={'text'}
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.code}
                                    isTouched={formik.touched.code}
                                />
                                {/* <div className="flex items-end border w-full">
                                    <p className="h-[40px] w-[15%] border-r-2 bg-white text-[15px] font-[500] border-[#E2E2E2] text-center">
                                        INR
                                    </p> */}
                                <InputTag
                                    name="maximum_order_amount"
                                    label={'Min Order Amount'}
                                    type={'number'}
                                    disabled={
                                        payDetails === 'Discount' &&
                                        true
                                    }
                                    value={formik.values.maximum_order_amount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.maximum_order_amount}
                                    isTouched={
                                        formik.touched.maximum_order_amount
                                    }
                                />
                                {/* </div> */}
                            </div>
                            <div className="flex justify-between gap-5">
                                <InputTag
                                    name="start_date"
                                    label={'Start Date'}
                                    type={'date'}
                                    min={getTodayDate()}
                                    value={formik.values.start_date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.start_date}
                                    isTouched={formik.touched.start_date}
                                />
                                <InputTag
                                    name="end_date"
                                    label={'End Date'}
                                    type={'date'}
                                    min={getTomorrowDate()}
                                    value={formik.values.end_date}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.end_date}
                                    isTouched={formik.touched.end_date}
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="bg-[#267DF8] text-white text-[12px] font-[400] p-2 xl:p-3 px-5 xl:px-8 rounded-[8px] cursor-pointer"
                        >
                            {id ? 'Save' : 'Add'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

const InputTag = ({label,value,type,error,isTouched,disabled,...rest}:any) => {
    return (
        <div className="flex flex-col w-full space-y-1 relative">
            <label className="text-[15px] font-[500] text-[#9C9C9C]">
                {label}
            </label>
            {label === 'Discount' || label === 'Amount' ? (
                <div className="relative flex items-center justify-end w-full">
                    <p
                        className={`text-[#505050] text-[14px] font-[500] px-4 absolute ${
                            label === 'Amount' && 'left-0 border-r-2'
                        }`}
                    >
                        {label === 'Discount' && '%'}
                    </p>
                    <div className="flex w-full">
                        {label === 'Amount' && (
                            <div className="border-[1px] border-[#E2E2E2] text-[15px] font-[500] flex items-center p-2 rounded-l-[6px] bg-white">
                                INR
                            </div>
                        )}
                        <input
                            type={type}
                            value={value}
                            {...rest}
                            className={`numberClass p-1.5 border-[1px] border-[#E2E2E2] bg-white ${
                                label === 'Amount'
                                    ? 'rounded-r-[6px]'
                                    : 'rounded-[6px]'
                            } h-[40px] w-full`}
                        />
                        {error && isTouched && (
                            <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-23px] right-0">
                                {error}
                            </p>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex">
                    {label === 'Min Order Amount' && (
                        <div className="border-[1px] border-[#E2E2E2] text-[15px] font-[500] flex items-center p-2 rounded-l-[6px] bg-white">
                            INR
                        </div>
                    )}
                    <input
                        type={type}
                        value={value}
                        {...rest}
                        disabled={disabled && disabled}
                        className={`${
                            label === 'Min Order Amount'
                                ? 'rounded-r-[6px]'
                                : 'rounded-[6px]'
                        } numberClass p-1.5 border-[1px] border-[#E2E2E2] bg-white h-[40px] w-full`}
                    />
                    {error && isTouched && (
                        <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-23px] right-0">
                            {error}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
}

const Textarea = ({label,value,error,isTouched, ...rest}:any) => {
    return (
        <div className="flex flex-col w-full">
            <label className="text-[15px] font-[500] text-[#9C9C9C]">
                {label}
            </label>
            <div className="flex relative">
                <textarea
                    {...rest}
                    value={value}
                    className="shrinkToTwoLines h-[70px] p-1.5 resize-none w-full border-[1px] border-[#E2E2E2] bg-white rounded-[6px]"
                >
                    {value}
                </textarea>
                {error && isTouched && (
                    <p className="text-red-500 text-[14px] font-[500] absolute bottom-[-23px] right-0">
                        {error}
                    </p>
                )}
            </div>
        </div>
    );
}

const RadioType = ({label,type,...rest}:any) => {
    return (
        <div className="flex items-center p-1.5 border-[1px] border-[#E2E2E2] bg-white rounded-[6px] h-[40px] w-full cursor-pointer">
            <input id={label} type={type} {...rest} />
            <label htmlFor={label} className="ml-2 text-[15px] font-[500] text-[#9C9C9C]">{label}</label>
        </div>
    );
}
