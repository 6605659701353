import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '.';
import { Headers } from './Components/Headers/Headers';
import { SideNav } from './Components/SideNav/SideNav';
import { RightSideNav } from './Components/RightSideNav/RightSideNav';
import { ProfileWindow } from './Components/Headers/Headers';
import { readRecord } from './Services/backend/apiCalls';
import { setAppConfig } from './Redux/Reducers/adminReducers';

export const Margin = () => {
    const { showProfileModal } = useAppSelector(({ modal }: any) => modal);
    const dispatch = useAppDispatch();
    useEffect(() => {
        (async () => {
            const appConfigs = await readRecord({}, 'admin/adminconfigs');
            console.log(appConfigs);
            if (appConfigs.status) {
                dispatch(setAppConfig(appConfigs.data[0]));
            }
        })();
    }, []);
    return (
        <div className="bg-[#f3fafc] relative">
            <Headers />
            {showProfileModal && <ProfileWindow />}
            <div className="h-auto flex overflow-x-hidden relative">
                <SideNav />
                <RightSideNav />
            </div>
        </div>
    );
};
