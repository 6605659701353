import { PayloadAction , createSlice } from "@reduxjs/toolkit";
import { RequestTypes, RequestState } from "../Types/directRequestType";

const initialState: RequestState = {
    Value: {
        input:[],
        minAmount:'',
        maxAmount:'',
        filters:{}
    }
}

const drSlice = createSlice({
    name: "directRequest",
    initialState,
    reducers: {
        setDirectRequest: (state: RequestState, action: PayloadAction<any>) => {
            state.Value = {...state.Value,...action.payload};
        },
        setFilters:(state:RequestState,action:PayloadAction<any>)=>{
            if(action.payload.value===""){
                delete state.Value.filters[action.payload.name];
            }else if(!action.payload){
                state.Value.filters={}
            }else{
                state.Value.filters={...state.Value.filters,[action.payload.name]:action.payload.value};
            }
        }
    }
})

export const { setDirectRequest,setFilters } = drSlice.actions;
export default drSlice.reducer;