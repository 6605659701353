import React from 'react';
import { useLocation } from 'react-router-dom';
import { InfoPage } from './Components/InfoPage/Infopage';

export const Illustration = ({children}:any) => {
    const location=useLocation();
  return (
    <div className='flex min-h-screen'>
      <InfoPage />
      {children}
    </div>
  )
}
