import React from 'react'
import { Input } from '../Elements/Input/Input';

export const ReminderInput = ({formik,value,selectedTemplate,plainTextContent}:any) => {
  return (
      <div className="mt-4">
          {['Email', 'Reminder'].includes(value) && (
              <Input
                  name="subject"
                  label={'Subject'}
                  placeholder={'Enter Subject'}
                  type={'text'}
                  disabled={!value.length || !selectedTemplate.length}
                  labeldesign={'text-[15px] mb-2'}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.subject}
                  isTouched={formik.touched.subject}
              />
          )}
          <Input
              name="content"
              label={'Content'}
              disabled={!value.length || !selectedTemplate.length}
              placeholder={'Enter Content'}
              type={'text'}
              labeldesign={'text-[15px] mt-5'}
              value={plainTextContent}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.content}
              isTouched={formik.touched.content}
          />
      </div>
  );
}
