import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector,useAppDispatch } from '../..';
import { showAllCouponsModal } from '../../Redux/Reducers/popupReducers';
import { PopupForCoupons } from './PopupForCoupons';
import { readRecord, postRecord, updateRecord, deleteRecord } from '../../Services/backend/apiCalls';
import { setCoupon } from '../../Redux/Reducers/couponsReducers';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useFormik } from 'formik';
import { postCouponsError } from '../../Services/Formik/Formik';
import { ModalBox } from '../UI_Modal/UI_Modal';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const dateFormat = (date:any) => {
    let dateText;
    dateText = moment(date).format('DD/MM/YYYY');
    return dateText;
};

export const Coupons = () => {
    const { id }: any = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [payDetails, setPayDetails] = useState('Amount');

    const [show, setShow] = useState(false);
    const [selectUserId, setSelectUserId] = useState(null);
    
    const AddImageModel = () => {
        formik.setValues(
            {
                amount: '',
                code: '',
                description: '',
                discount: '',
                maximum_order_amount: '',
                end_date: '',
                start_date: '',
                title: '',
            },
            true
        );
        navigate(`/dashboard/coupons`);
        dispatch(showAllCouponsModal(true));
    };

        const [{ token }, { coupons }, { showCouponsModal }] =
            useAppSelector(({ signin, getCoupon, modal }: any) => [
                signin['signinValue'],
                getCoupon['CouponsData'],
                modal,
            ]);

    const getCoupons = async () => {
        const getResponse = await readRecord({}, 'admin/coupons');
        if (getResponse.status) {
            navigate(`/dashboard/coupons`);
            dispatch(setCoupon({ coupons: getResponse.data }));
        } else if (
            getResponse.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getResponse.message);
        } else {
            toast.error(getResponse.message);
        }
    }

    useEffect(() => {
        getCoupons()
    }, [])

    const onChangeInputValue = (e: any, text: any) => {
        postCouponsError(values.amount);
        setPayDetails(text);
        // if (text === 'Amount') {
        //     setFieldValue('maximum_order_amount', '');
        // } else {
        //     setFieldValue('discount', '');
        // }
    };

    const editData = async (ID: any) => {
        dispatch(showAllCouponsModal(true));
        navigate(`/dashboard/coupons/${ID['id']}`);
    }

    const InstantChangeWhileEdit = async (getID: any) => {
        const getResponseWhileEdit = await readRecord({}, 'admin/coupons');
        if (getResponseWhileEdit.status) {
            navigate(`/dashboard/coupons`);
            dispatch(setCoupon({ coupons: getResponseWhileEdit.data }));
        } else if (
            getResponseWhileEdit.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getResponseWhileEdit.message);
        } else {
            toast.error(getResponseWhileEdit.message);
        }
    };

    const InstantDeleteFunc = (couponID: any) => {
        const removeID = coupons.filter((idx: any) => idx['id'] !== couponID);
        dispatch(setCoupon({ coupons: [...removeID]}))
    }   
    
    const deleteData = async () => {
        const deleteResponse = await deleteRecord(
            { id:selectUserId },
            'admin/coupons'
        );
        if (deleteResponse.status) {
            InstantDeleteFunc(selectUserId);
            toast.success(deleteResponse.message)
        } else toast.error(deleteResponse.message)
    }

        const InstantGetData = (data: any) => {
            dispatch(setCoupon({ coupons: [...coupons, data] }));
        };

    const submitRequestForCoupons = async () => {
                if (values.start_date > values.end_date) {
                    return formik.setFieldError(
                        'end_date',
                        'Change End Date'
                    );
                }
                if (payDetails === 'Amount') {
                    setFieldValue('discount', '');
                } else {
                    setFieldValue('amount', '');
                    setFieldValue('maximum_order_amount', '');
                }
        const {
            code,
            title,
            description,
            discount,
            amount,
            start_date,
            end_date,
            maximum_order_amount,
        } = values;
        const body = {
            ...(id && { id: Number(id) }),
            code: code,
            //check code already exists && // char more than 5
            title: title,
            description: description,
            ...(payDetails === 'Discount' && {
                discount: Number(discount),
            }),
            ...(payDetails === 'Amount' && {
                amount: Number(amount),
            }),
            start_date: start_date,
            end_date: end_date,
            ...(payDetails === 'Amount' && {
                maximum_order_amount: Number(maximum_order_amount),
            }),
        };
        const CouponResponse = await(
            id
                ? updateRecord(body, 'admin/coupons')
                : postRecord(body, 'admin/coupons')
        );
        if (CouponResponse.status) {
            toast.success(CouponResponse.message);
            (id?InstantChangeWhileEdit(id):InstantGetData(CouponResponse.data))
            dispatch(showAllCouponsModal(false));
            navigate(`/dashboard/coupons`);
            resetForm();
        } else if (
            CouponResponse.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(CouponResponse.message);
        } else toast.error(CouponResponse.message);
    };  

        const input = {
            code: '',
            title: '',
            description: '',
            discount: '',
            start_date: '',
            end_date: '',
            amount: '',
            maximum_order_amount: '',
        };

        const formik = useFormik({
            initialValues: input,
            validationSchema: postCouponsError,
            onSubmit: () => {
                submitRequestForCoupons();
            },
        });
    
        const {
            values,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            errors,
            resetForm,
        } = formik;
    
        const deleteModal = (flag: any) => {
            setShow(false);
            setSelectUserId(null);
            if (flag === 'yes') {
                deleteData();
            } else {
                setShow(false);
            }
        };

        const openModal = (val: any) => {
            setShow(true);
            setSelectUserId(val);
        };

  return (
      <div className="w-11/12 space-y-10 mx-auto mt-8">
          <ModalBox show={show} fun={deleteModal} />
          <h1 className="text-[18px] font-[600]">Configure Coupon</h1>
          <div className="flex justify-between items-center">
              <p className="text-[16px] font-[500]">Coupons</p>
              <button
                  className="bg-[#267DF8] text-white text-[12px] font-[400] p-2.5 px-6 xl:px-8 rounded-[8px]"
                  onClick={AddImageModel}
              >
                  Add
              </button>
          </div>
          <div className="pb-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full">
              {coupons &&
                  coupons.map((coupon: any, index: number) => {
                      return (
                          <div
                              key={index}
                              className="bg-[#F9FBFC] w-[100%] p-3 space-y-5"
                          >
                              <p className="text-[#267DF8] text-[18px] font-[600]">
                                  {coupon.title}
                              </p>
                              <p className="break-words text-[#686B78] text-[14px] font-[400] min-h-[40px]">
                                  {coupon.description}
                              </p>
                              <div className="flex justify-between">
                                  <p className="flex text-[12px] font-[600]">
                                      Start Date :
                                      <span className="ml-1 text-[#686B78] font-[400]">
                                          {dateFormat(coupon['start_date'])}
                                      </span>
                                  </p>
                                  <p className="flex text-[12px] font-[600]">
                                      End Date :
                                      <span className="ml-1 text-[#686B78] font-[400]">
                                          {dateFormat(coupon['end_date'])}
                                      </span>
                                  </p>
                              </div>
                              <div className="flex gap-3">
                                  <button
                                      className="bg-[#E5ECFF] p-3 w-[65px] rounded-[2px] text-[#267DF8] text-[10px] font-[500]"
                                      onClick={() => editData(coupon)}
                                  >
                                      Edit
                                  </button>
                                  <button
                                      className="bg-[#FF6963] p-3 w-[65px] rounded-[2px] text-white text-[10px] font-[400]"
                                      onClick={() => openModal(coupon['id'])}
                                  >
                                      Delete
                                  </button>
                              </div>
                          </div>
                      );
                  })}
          </div>
          {showCouponsModal && (
              <PopupForCoupons
                  formik={formik}
                  onChangeInputValue={onChangeInputValue}
                  payDetails={payDetails}
                  chooseField={setPayDetails}
                  //   payDetailsRef={payDetailsRef}
              />
          )}
      </div>
  );
}
