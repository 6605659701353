import React, { useEffect, useState } from 'react';
import { Input } from '../../Elements/Input/Input';
import curveLine from '../../../assets/Images/curveLine.png';
import { Providers } from '../Providers/Providers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateValidationSignSchema } from '../../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import Login_Logo from '../../../assets/Images/Login_Logo.svg';
import { PulseLoader } from 'react-spinners';
import { useAppDispatch } from '../../../';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { setSignValue } from '../../../Redux/Reducers/signinUserReducers';
import { authentication } from '../../../Firebase/firebase';
import { readRecord } from '../../../Services/backend/apiCalls';
import { camelCaseConvertForErrorMessage } from '../../../Services/commonFunctions';
// import { isTrainer } from '../../../Services/commonFunctions';

export const isTrainer = (role: string) => {
    return ['TI', 'FL'].includes(role);
};

export const roleBasedPageSwitch = async (
    role: any,
    callbackFunction: any,
    uid: any,
    token: any,
    storeDataDispatch: any
) => {
    // const dispatch = useAppDispatch();
    localStorage.setItem('searchTerm', '');
    if (!role) {
        return callbackFunction('/role');
    }
    const userData = await readRecord({ uid }, 'profiles');
    // dispatch(setSignValue({ username: userData.data.name }));
    // if (!userData.status) {
    //     return callbackFunction('/register/personal');
    // }
    storeDataDispatch({
        username: userData?.data?.name,
        // ...(!isTrainer(role) && {
        //     attendedCourses: [...new Set(userData.data.attended_courses)],
        // }),
    });
    callbackFunction('/dashboard');
};

export const storeLoggedUserDetails = async (
    userObject: any,
    navigate: any,
    storeDataDispatch: any
) => {
    const { user } = userObject;
    const tokenResult = await user.getIdTokenResult();
    const { token, claims: userDetails } = tokenResult;
    if (userObject.providerId === null && !user.emailVerified) {
        return toast.error(
            camelCaseConvertForErrorMessage('please verify your email')
        );
    }
    const { user_id, email, role } = userDetails;
    await storeDataDispatch({
        token,
        email,
        uid: user_id,
        role,
        isTokenExpired: false,
    });
    toast.success(camelCaseConvertForErrorMessage('logged successfully'));
    await roleBasedPageSwitch(
        role,
        navigate,
        user_id,
        token,
        storeDataDispatch
    );
};

export const authErrors: any = {
    'auth/user-not-found': 'Please sign up',
    'auth/email-already-exists':
        'Email Id already exists, Please sign in to continue',
    'auth/wrong-password': 'Invalid password',
    'auth/too-many-requests': 'Please try again after 30 seconds',
    'auth/invalid-login-credentials': 'Invalid Login Credentials',
};

export const SignIn = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const errormessage = searchParams.get("errormessage");
    // let [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);

    const forgetpassword = () => {
        navigate('/email/verify');
    };

    const storeUserData = (data: any) => {
        // dispatch(setSignValue(data));
        // console.log(data);
        dispatch(setSignValue(data));
    };

    useEffect(() => {
        if (errormessage) {
            toast.error(errormessage)
            navigate("/");
        }
    }, [])

    const handleSubmit = async () => {
        if (window.navigator.onLine) {
            const { email, password } = formik.values;
            setClicked(true);
            try {
                // toast.loading("load")
                const signedResult = await signInWithEmailAndPassword(
                    authentication,
                    email,
                    password
                );
                storeLoggedUserDetails(signedResult, navigate, storeUserData);
            } catch (error: any) {
                const errorMessage = error.message;
                const code = error.code;
                // The email of the user's account used.
                // const email = error.customData.email;
                toast.error(
                    authErrors[error.code]
                );
            }
            setClicked(false);
        } else {
            toast.error(
                camelCaseConvertForErrorMessage(
                    'Please check your internet connection'
                )
            );
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: CreateValidationSignSchema,
        onSubmit: () => {
            handleSubmit();
        },
    });

    return (
        <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12 flex justify-center items-center">
            <img src={curveLine} alt="" className="absolute top-0 right-0" />
            <form
                className="flex flex-col justify-center items-start w-9/12 lg:w-6/12 xl:w-4/12 mx-auto space-y-8"
                onSubmit={formik.handleSubmit}
            >
                <img src={Login_Logo} alt="Login_Logo" className="w-[190px]" />
                <div className="w-full space-y-4">
                    <Input
                        design={'w-full'}
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email}
                        isTouched={formik.touched.email}
                        impo="impo"
                    />
                    <Input
                        design={'w-full'}
                        paddingForEye={'pr-10'}
                        name="password"
                        label="Password"
                        type={'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password}
                        isTouched={formik.touched.password}
                        impo="impo"
                    />
                </div>
                <div className="w-full flex justify-end">
                    <p
                        className="text-[12px] font-[300] cursor-pointer"
                        onClick={forgetpassword}
                    >
                        Forgot Password?
                    </p>
                </div>
                <button
                    className="cursor-pointer bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-3 text-[12px] font-[400] text-white mt-6 rounded-md"
                    disabled={clicked}
                    type="submit"
                >
                    Sign In
                </button>
                <div className="flex items-center w-full pt-2">
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                    <div className="flex-grow-0 mx-5 text-[14px] font-[300]">
                        or
                    </div>
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                </div>
                <div className="flex flex-col w-full items-center">
                    <Providers />
                    <p className="text-[12px] font-[300]">
                        Don't have an account ?{' '}
                        <span
                            className="text-[#0666ED] text-[13px] font-[500] cursor-pointer"
                            onClick={() => navigate('/register')}
                        >
                            Sign Up for free
                        </span>
                    </p>
                </div>
            </form>
        </div>
    );
};
