import sendEmail from '../../assets/Images/sendEmail.svg';
import showAd from '../../assets/Images/showAd.svg';
import showCoupons from '../../assets/Images/showCoupons.svg';
import showNotify from '../../assets/Images/showNotify.svg';
import showUpcomingTraining from '../../assets/Images/showUpcomingTraining.svg';
import enableCancellation from '../../assets/Images/enableCancellation.svg';
import Bidding from '../../assets/Images/Bidding.svg';
import CMS from '../../assets/Images/CMS.svg';
import Comparability from '../../assets/Images/Comparability.svg';
import DirectRequest from '../../assets/Images/DirectRequest.svg';
import Payment from '../../assets/Images/Payment.svg';
import ReviewAndRating from '../../assets/Images/ReviewAndRating.svg';
import showAnalytics from '../../assets/Images/showAnalytics.svg';
import showInterest from '../../assets/Images/showInterest.svg';
import showOpportunities from '../../assets/Images/showOpportunities.svg';
import bidSuggesion from '../../assets/Images/bidSuggesion.svg';
import drSuggesion from '../../assets/Images/drSuggesion.svg';

export const SettingsJson = [
    {
        img: showAd,
        title: 'Show Advertisements',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'show_ads',
    },
    {
        img: showCoupons,
        title: 'Show Coupons',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'show_coupons',
    },
    {
        img: showUpcomingTraining,
        title: 'Send Upcoming Training Reminder',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'send_upcoming_reminder',
    },
    {
        img: showNotify,
        title: 'Send Notification',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'send_notifications',
    },
    {
        img: sendEmail,
        title: 'Send Email',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'send_email_notifications',
    },
    {
        img: enableCancellation,
        title: 'Enable Cancellation Fee',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'show_cancellation_fee',
    },
    {
        img: Comparability,
        title: 'Enable Comparability',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'comparability_action',
    },
];

export const SettingJSONForCMS = [
    // {
    //     img: ReviewAndRating,
    //     title: 'Enable Review/Rating/feedback',
    //     description:
    //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    //     name: 'show_cancellation_fee',
    // },
    // {
    //     img: Payment,
    //     title: 'Enable Payment',
    //     description:
    //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    //     name: 'show_cancellation_fee',
    // },
    {
        img: Bidding,
        title: 'Enable Bidding',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'bidding_action',
    },
    {
        img: bidSuggesion,
        title: 'Enable Bidding Suggesion',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'bidding_suggestions',
    },
    {
        img: DirectRequest,
        title: 'Enable Direct Request',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'direct_request_action',
    },
    {
        img: drSuggesion,
        title: 'Enable Direct Request Suggesion',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'direct_request_suggestions',
    },
    {
        img: CMS,
        title: 'Enable CMS',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'cms_action',
    },
    // {
    //     img: Comparability,
    //     title: 'Enable Comparability',
    //     description:
    //         'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    //     name: 'comparability_action',
    // },
    {
        img: showInterest,
        title: 'Show Interest',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'show_interest_action',
    },
    {
        img: showOpportunities,
        title: 'Show Opportunities',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'show_opportunities_action',
    },
    {
        img: showAnalytics,
        title: 'Show Analytics',
        description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        name: 'show_analytics_action',
    },
];
