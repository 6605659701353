import React from 'react';
import { useParams } from 'react-router-dom';
import { Advertisements } from '../Advertisements/Advertisements';
import { Coupons } from '../Coupons/Coupons';
import { Reminder } from '../Remainder/Reminder';
import { Fees } from '../Fees/Fees';
import { Settings } from '../Settings/Settings';
import { EditOpportunity } from '../SideNav/EditOpportunity';

export const RightSideNav = () => {
    const params = useParams();
    // console.log(params.dashboardScreen)
    const routeName: any = params.dashboardScreen;

    const screens: any = {
        advertisements: <Advertisements />,
        coupons: <Coupons />,
        reminder: <Reminder />,
        fees: <Fees />,
        settings: <Settings />,
        edittrendingcourse: <EditOpportunity />,
    };

    return (
        <div className="bg-white w-full">
            {screens[routeName] || <Advertisements />}
        </div>
    );
};
