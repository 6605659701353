import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { adTypes, adState } from '../Types/advertisementType';

const initialState: adState = {
    AdvertisementData: {
        advertisement: [],
    },
};

const advertisementSlice = createSlice({
    name: 'getAd',
    initialState,
    reducers: {
        setAdvertisement: (state: adState, action: PayloadAction<any>) => {
            state.AdvertisementData = {
                ...state.AdvertisementData,
                ...action.payload,
            };  
        },
        setAdvertisementByIndex: (state: adState, action: PayloadAction<any>) => {
            console.log(action.payload)
            let updatedAds = state.AdvertisementData.advertisement.map((ad: any, i: number) => {
                if (ad.id === action.payload.id) {
                    return action.payload.data
                } return ad;
            })
            state.AdvertisementData = {advertisement: updatedAds};
        }
    },
});

export const { setAdvertisement, setAdvertisementByIndex } =
    advertisementSlice.actions;
export default advertisementSlice.reducer;
