import { Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { SignIn } from './Components/LoginFunctionality/Signin/Signin';
import { EmailVerification } from './Components/LoginFunctionality/Signin/EmailVerification';
import { Illustration } from './Illustration';
import { Margin } from './Margin';

function App() {
    return (
        <div className="font-poppins">
            <Toaster />
            <Routes>
                <Route
                    path="/"
                    element={
                        <Illustration>
                            <SignIn />
                        </Illustration>
                    }
                />
                <Route
                    path="/email/verify"
                    element={
                        <Illustration>
                            <EmailVerification />
                        </Illustration>
                    }
                />
                <Route path="/dashboard" element={<Margin />} />
                <Route
                    path="/dashboard/:dashboardScreen"
                    element={<Margin />}
                />
                <Route
                    path="/dashboard/:dashboardScreen/:id"
                    element={<Margin />}
                />
                {/* <Route path="/dashboard/form" element={<EditOpportunity />} /> */}
            </Routes>
        </div>
    );
}

export default App;
