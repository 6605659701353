import React, { useState } from 'react'

export const SelectTemplates = ({ templateTabs, setSelectedTemplate,selectedTemplate }: any) => {
    const [isLess, setIsLess] = useState(true);
    const removedEmptyObjects = templateTabs.filter((temp: any) => Object.keys(temp).length > 0);

  return (
      <div className="flex mt-4">
          <div className="flex w-10/12 flex-wrap">
              {removedEmptyObjects
                  .slice(0, isLess ? 5 : removedEmptyObjects.length)
                  .map((template: any, index: number) => {
                      return (
                          <p
                              className={`bg-[#F4F7FF] rounded-[6px] mt-2 p-2 text-[13px] mr-2 ${
                                  selectedTemplate === template.value
                                      ? 'text-[#267DF8]'
                                      : 'text-black'
                              }`}
                              key={index}
                              role={'button'}
                              onClick={() =>
                                  setSelectedTemplate(template.value)
                              }
                          >
                              {template.displayText}
                          </p>
                      );
                  })}
          </div>
          {removedEmptyObjects.length > 4 && (
              <p
                  className="select-none w-2/12 font-semibold text-[#267DF8]"
                  role={'button'}
                  onClick={() => setIsLess(!isLess)}
              >
                  {isLess ? 'Show More' : 'Show Less'}
              </p>
          )}
      </div>
  );
}
