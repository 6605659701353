import { useEffect, useState } from 'react';
// import TrisektLogo from "../../assets/Images/TrisektLogo.svg";
import TrisektLogo from '../../assets/Images/trisektLogo.svg';
import HeaderUser from '../../assets/Images/HeaderUser.svg';
import profileDropDown from '../../assets/Images/profileDropDown.svg';
// import searchIcon from '../../assets/Images/searchIcon.svg';
import { useAppDispatch, useAppSelector } from '../..';
import { showProfileDetailModal } from '../../Redux/Reducers/popupReducers';
import userPic from '../../assets/Images/userPic.svg';
import Logout from '../../assets/Images/Logout.svg';
import { useNavigate } from 'react-router-dom';

export const Headers = () => {
    const dispatch = useAppDispatch();
    const { showProfileModal } = useAppSelector(({ modal }: any) => modal);
    const [openMod, setOpenMod] = useState(false);

    useEffect(() => {
        if (openMod) {
            dispatch(showProfileDetailModal(true));
        } else {
            dispatch(showProfileDetailModal(false));
        }
    }, [openMod]);

    const profileModal = () => {
        setOpenMod(!openMod);
        // if (openMod) {
        //     dispatch(showProfileDetailModal(true));
        // } else {
        //     dispatch(showProfileDetailModal(false));
        // }
    };

    return (
        <div className="h-[12vh] flex justify-between items-center p-4">
            <img
                src={TrisektLogo}
                alt="TriLogo"
                className="w-[140px] h-[50px]"
            />
            <div className="flex items-center gap-8 mr-8">
                {/* <input
                  type="text"
                  name="Search"
                  placeholder="Search"
                  className="border p-0.5 h-[35px] px-6 rounded-full"
              /> */}
                {/* <img src={searchIcon} alt="search" className="absolute px-4" /> */}
                <div
                    className="flex gap-2 cursor-pointer"
                    onClick={profileModal}
                >
                    <img src={HeaderUser} alt="HeaderUsers" className="" />
                    <img
                        src={profileDropDown}
                        alt="profileDropDown"
                        className="cursor-pointer"
                    />
                </div>
            </div>
        </div>
    );
};

export const ProfileWindow = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { role, username } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );

    const logoutFunc = () => {
        dispatch(showProfileDetailModal(false));
        navigate('/');
    };
    return (
        <div className="p-2 flex flex-col justify-around absolute right-14 z-10 top-14 border-[0.2px] border-[#DCDCDC] bg-white min-w-[188px] min-h-[93px]">
            <div className="flex gap-4">
                <img src={userPic} alt="Users" />
                <div>
                    <h1 className="font-[500] text-[12px] break-words">
                        {username}
                    </h1>
                    <div className="flex">
                        <p className="text-[#267DF8] text-center text-[9px] font-[500] bg-[#F4F7FF] p-0.5 px-2.5 rounded-[35px] tracking-widest">
                            {role}
                        </p>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className="flex gap-3 cursor-pointer" onClick={logoutFunc}>
                <img src={Logout} alt="Logout" />
                <p className="text-[12px] font-[400]">Logout</p>
            </div>
        </div>
    );
};
