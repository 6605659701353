import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
// import { CheckIcon } from '@heroicons/react/24/outline';

export const ModalBox = ({ show, fun, title }: any) => {
    console.log(title, 'title');
    const [modalState, setModalState] = useState(false);

    const modalClose = (flag: any) => {
        setModalState(false);
        fun(flag);
    };

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setModalState}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon
                                            className="h-6 w-6 text-green-600"
                                            aria-hidden="true"
                                        />
                                    </div> */}
                                    <div className="mt-3 text-center sm:mt-5">
                                        {/* <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Payment successful
                                        </Dialog.Title> */}
                                        <div className="mt-2">
                                            <p className="text-base font-semibold leading-6 text-gray-900">
                                                Are you sure, You want to
                                                delete?
                                            </p>
                                        </div>
                                        <div className="flex gap-8 m-auto w-[35%] mt-10">
                                            <button
                                                className="bg-[#F3F7FF] text-[#0F559E] px-4 py-1 tracking-wider"
                                                onClick={() =>
                                                    modalClose('cancel')
                                                }
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                className="bg-[#0F559E] text-[#F3F7FF] px-4 py-1 tracking-wider"
                                                onClick={() =>
                                                    modalClose('yes')
                                                }
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"></div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
