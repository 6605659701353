import Advertisement from '../../assets/Images/Advertisement.svg';
import AdvertisementSelect from '../../assets/Images/AdvertisementSelect.svg';
import Coupons from '../../assets/Images/Coupons.svg';
import CouponsSelect from '../../assets/Images/CouponsSelect.svg';
import Remainder from '../../assets/Images/Remainder.svg';
import RemainderSelect from '../../assets/Images/RemainderSelect.svg';
import Fees from '../../assets/Images/Fees.svg';
import FeesSelect from '../../assets/Images/FeesSelect.svg';
import Settings from '../../assets/Images/Settings.svg';
import SettingsSelect from '../../assets/Images/SettingsSelect.svg';
import editIcon from '../../assets/Images/editIcon.svg';

export const Sections = [
    {
        img: Advertisement,
        selectedImg: AdvertisementSelect,
        alt: 'advertisements',
        tab: 'Advertisement',
    },
    {
        img: Coupons,
        selectedImg: CouponsSelect,
        alt: 'coupons',
        tab: 'Coupons',
    },
    {
        img: Remainder,
        selectedImg: RemainderSelect,
        alt: 'reminder',
        tab: 'Reminder',
    },
    {
        img: Fees,
        selectedImg: FeesSelect,
        alt: 'fees/cancellation',
        tab: 'Fees',
    },
    {
        img: Settings,
        selectedImg: SettingsSelect,
        alt: 'settings',
        tab: 'Settings',
    },
    {
        img: editIcon,
        selectedImg: editIcon,
        alt: 'edittrendingcourse',
        tab: 'Edit Trending Course',
    },
];
