export const Cancellation = ({ formik }: any) => {
    return (
        <div className="flex gap-6 w-3/5 xl:w-2/5 mt-10">
            <div className="flex items-center gap-4">
                <LeftSide formik={formik} />
                <RightSide formik={formik} />
            </div>
            <PercentValue formik={formik} />
        </div>
    );
};

const LeftSide = ({ formik }: any) => {
    const CancellationJSON = [
        {
            sno: '1.',
            name: 'data11',
        },
        {
            sno: '2.',
            name: 'data12',
        },
        {
            sno: '3.',
            name: 'data22',
        },
    ];
    return (
        <div className="space-y-8">
            {CancellationJSON.map((cancel, index) => {
                return (
                    <div key={index} className="flex items-center gap-4">
                        <h1>{cancel.sno}</h1>
                        <div className="relative flex items-center justify-end">
                            <p className="text-[#505050] text-[14px] font-[500] border-l-2 px-2 absolute">
                                Days
                            </p>
                            <div className="flex">
                                <input
                                    name={cancel.name}
                                    type="number"
                                    maxLength={3}
                                    value={formik.values[cancel.name]}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    disabled={true}
                                    className="numberClass px-2 h-[42px] rounded-[6px] border-[1px] border-[#E2E2E2]"
                                />
                                {formik.touched[cancel.name] &&
                                    formik.errors[cancel.name] && (
                                        <span className="text-red-500 text-[14px] font-[500] absolute bottom-[-22px]">
                                            {formik.errors[cancel.name]}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <p>to</p>
                    </div>
                );
            })}
        </div>
    );
};

const RightSide = ({ formik }: any) => {
    const CancellationJSON = [
        {
            name: 'data12',
        },
        {
            name: 'data22',
        },
        {
            name: 'data32',
        },
    ];
    return (
        <div className="space-y-8">
            {CancellationJSON.map((cancel, index) => {
                return (
                    <div key={index} className="flex items-center gap-10">
                        <div className="relative flex items-center justify-end">
                            <p className="text-[#505050] text-[14px] font-[500] border-l-2 px-2 absolute">
                                Days
                            </p>
                            <div className="flex">
                                <input
                                    name={cancel.name}
                                    type="number"
                                    value={formik.values[cancel.name]}
                                    maxLength={3}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    className="numberClass px-2 h-[42px] rounded-[6px] border-[1px] border-[#E2E2E2]"
                                />
                                {formik.touched[cancel.name] &&
                                    formik.errors[cancel.name] && (
                                        <span className="text-red-500 text-[14px] font-[500] absolute bottom-[-22px]">
                                            {formik.errors[cancel.name]}
                                        </span>
                                    )}
                            </div>
                        </div>
                        <p>-</p>
                    </div>
                );
            })}
        </div>
    );
};

const PercentValue = ({ formik }: any) => {
    const PercentJSON = [
        {
            name: 'percent1',
        },
        {
            name: 'percent2',
        },
        {
            name: 'percent3',
        },
    ];
    return (
        <div className="space-y-8">
            {PercentJSON.map((percent, idx) => {
                return (
                    <div
                        key={idx}
                        className="relative flex items-center justify-end"
                    >
                        <p className="text-[#505050] text-[14px] font-[500] px-2 absolute">
                            %
                        </p>
                        <div className="flex">
                            <input
                                name={percent.name}
                                type="number"
                                value={formik.values[percent.name]}
                                maxLength={3}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                className="numberClass px-2 h-[42px] rounded-[6px] border-[1px] border-[#E2E2E2]"
                            />
                            {formik.touched[percent.name] &&
                                formik.errors[percent.name] && (
                                    <span className="text-red-500 text-[14px] font-[500] absolute bottom-[-22px]">
                                        {formik.errors[percent.name]}
                                    </span>
                                )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
