export const getTomorrowDate = () => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const offset = tomorrow.getTimezoneOffset();
    tomorrow = new Date(tomorrow.getTime() - offset * 60 * 1000);
    return tomorrow.toISOString().split('T')[0];
};

export const getTodayDate = () => {
    let today = new Date();
    // let tomorrow = new Date();
    // tomorrow.setDate(today.getDate() + 1);
    const offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - offset * 60 * 1000);
    return today.toISOString().split('T')[0];
};
