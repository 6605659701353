import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../..';
import { UpcomingTraining } from './UpcomingTraining';
import { ReminderSection } from './ReminderSection';
import { NotificationSection } from './NotificationSection';
import { EmailSection } from './EmailSection';
import { showReminderTabs } from '../../Redux/Reducers/reminderReducer';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
    setReminderError,
    setNotificationSectionError,
    setEmailSectionError,
    emailTemplateSchema,
    notifySchema,
} from '../../Services/Formik/Formik';
import {
    readRecord,
    postRecord,
    updateRecord,
} from '../../Services/backend/apiCalls';
import { setReminderResponse } from '../../Redux/Reducers/reminderResponseReducer';
import { ReminderSubSection } from './ReminderSubSection';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const Reminder = () => {
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState('Trainer');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const userValue = { selectedUser, setSelectedUser };
    const templateValue = { selectedTemplate, setSelectedTemplate };
    const [templates, setTemplates] = useState<any>([]);
    const [isNewRecord, setNewRecord] = useState(false);
    const [reminderID, setReminderID] = useState();
    const [isSave, setIsSave] = useState(false);
    const [count, setCount] = useState(0);

    const [{ token }, { reminderResponse }, { reminder_days }] = useAppSelector(
        ({ signin, rem, appConfig }: any) => [
            signin['signinValue'],
            rem['ReminderData'],
            appConfig['appConfig'],
        ]
    );
    const dispatch = useAppDispatch();
    const { value } = useAppSelector(({ reminder }: any) => reminder);

    const handleTabChange = (obj: any) => {
        setIsSave(false);
        dispatch(showReminderTabs(obj));
    };

    useEffect(() => {
        if (reminder_days?.length) {
            setFieldValue('data1', reminder_days[0]);
            setFieldValue('data2', reminder_days[1]);
            setFieldValue('data3', reminder_days[2]);
        }
    }, [JSON.stringify(reminder_days)]);

    useEffect(() => {
        getAllTemplates();
    }, []);

    useEffect(() => {
        // setIsSave(false);
        const [filteredTemplate]: any = templates.filter(
            (temp: any) =>
                temp.unique ===
                selectedTemplate +
                    selectedUser.toLowerCase() +
                    value.toLowerCase()
        );
        setNewRecord(!filteredTemplate);
        ['Email', 'Reminder'].includes(value) &&
            setFieldValue(
                'subject',
                filteredTemplate ? filteredTemplate.subject : ''
            );
        setFieldValue(
            'content',
            filteredTemplate ? filteredTemplate.template : ''
        );
        setFieldValue('id', filteredTemplate ? filteredTemplate.id : '');
    }, [JSON.stringify(templates), value, selectedTemplate, selectedUser]);

    useEffect(() => {
        setSelectedTemplate('');
    }, [selectedUser, value]);

    const getAllTemplates = async () => {
        const getTemplates = await readRecord({}, 'admin/templates');
        if (getTemplates.status) {
            const responseData = getTemplates.data;
            // console.log(responseData);
            setTemplates(responseData);

            // }
        } else if (
            getTemplates.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getTemplates.message);
        } else toast.error(getTemplates.message);
    };

    // UpcomingTraining

    const input = {
        data1: '',
        data2: '',
        data3: '',
    };

    const templateInput = {
        ...(['Email', 'Reminder'].includes(value) && { subject: '' }),
        content: '',
    };

    const updateReminder = async () => {
        if (isSave) {
            const response = await updateRecord(
                {
                    reminder_days: [
                        Number(values.data1),
                        Number(values.data2),
                        Number(values.data3),
                    ],
                    id: 1,
                },
                'admin/adminconfigs'
            );
            if (response.status) {
                setIsSave(false);
                return toast.success(
                    camelCaseConvertForErrorMessage('Updated Successfully')
                );
            }
            toast.error(response.message);
        }
        setIsSave(false);
    };

    const templateAPIAction = async () => {
        if (isSave) {
            const apiFunc = isNewRecord ? postRecord : updateRecord;
            const response = await apiFunc(
                {
                    ...(isNewRecord
                        ? {
                              type: selectedTemplate,
                              notify_type: value.toLowerCase(),
                              user_type: selectedUser.toLowerCase(),
                          }
                        : {
                              id: values.id,
                          }),
                    template: modifyContentToHTML(values.content),
                    ...(value === 'Email' && { subject: values.subject }),
                },
                'admin/templates'
            );
            if (response.status) {
                setCount(0);
                setIsSave(false);
                if (isNewRecord) {
                    setTemplates([...templates, response.data]);
                } else {
                    const updateTemplate = templates.map((temp: any) => {
                        if (temp.id === values.id) {
                            return {
                                ...temp,
                                ...(['Email', 'Reminder'].includes(value) && {
                                    subject: values.subject,
                                }),
                                template: modifyContentToHTML(values.content),
                            };
                        }
                        return temp;
                    });
                    setTemplates(updateTemplate);
                }
                return toast.success(
                    camelCaseConvertForErrorMessage('Template updated')
                );
            }
            toast.error(response.message);
        }

        setIsSave(false);
        // const [userAndTemplate] = templates.filter((temp: any) => (temp.unique === selectedTemplate + selectedUser.toLowerCase() + value.toLowerCase()));
    };

    const modifyContentToHTML = (content:any) => {
        const htmlContent = content.replace(/\n/g, '<br>');
        return htmlContent;
    };

    const TypeValues: any = {
        UpcomingTraining: {
            input: input,
            validation: setReminderError,
            submitFunc: updateReminder,
        },
        Reminder: {
            input: templateInput,
            // validation: emailTemplateSchema,
            submitFunc: templateAPIAction,
        },
        Notification: {
            input: templateInput,
            // validation: notifySchema,
            submitFunc: templateAPIAction,
        },
        Email: {
            input: templateInput,
            // validation: emailTemplateSchema,
            submitFunc: templateAPIAction,
        },
    };

    const formik = useFormik({
        initialValues: TypeValues[value]['input'],
        validationSchema: TypeValues[value]['validation'],
        onSubmit: TypeValues[value]['submitFunc'],
    });

    const {
        values,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        setFieldValue,
        errors,
        resetForm,
    } = formik;

    useEffect(() => {
        if (isSave) {
            setBtnStyle(
                'cursor-pointer bg-[#267DF8] rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6'
            );
        } else {
            setBtnStyle(
                'cursor-pointer bg-gray-300 rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6'
            );
        }
    }, [isSave]);
    const { data1, data2, data3 } = values;
    const content = values.content || '';
    useEffect(() => {
        if (count > 1 && (data1 || data2 || data3 || content)) {
            setIsSave(true);
        }
        // setIsSave(false);
        setCount(count + 1);
    }, [data1, data2, data3, content]);

    const configurationTab = [
        'UpcomingTraining',
        'Reminder',
        'Notification',
        'Email',
    ];

    let style =
        'cursor-pointer bg-[#267DF8] rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6';
    let [btnStyle, setBtnStyle] = useState(style);

    const tabChange = (value: any) => {
        if (value) {
            setIsSave(true);
        }
    };

    const convertHTMLToPlainText = (html:any) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        // Replace <br> tags with line breaks
        tempElement.querySelectorAll('br').forEach((br) => {
            const lineBreak = document.createTextNode('\n');
            // Check if the parent node exists before replacing the child
            if (br.parentNode) {
                br.parentNode.replaceChild(lineBreak, br);
            }
        });
        // Use textContent to get the plain text content with line breaks
        const plainTextContent = tempElement.textContent;
        return plainTextContent;
    };

    const plainTextContent = convertHTMLToPlainText(values.content);

    return (
        <div className="w-11/12 space-y-10 mx-auto mt-8">
            <h1 className="text-[18px] font-[600]">Reminder & Notify</h1>
            <form onSubmit={handleSubmit}>
                <div className="float-right -mt-4">
                    <button
                        type="submit"
                        // className="bg-[#267DF8] rounded-[8px] text-white text-[12px] font-[400] p-2.5 px-6"
                        className={btnStyle}
                    >
                        Save
                    </button>
                </div>
                <div>
                    <div className="flex gap-8 h-8 ml-4">
                        {configurationTab.map((obj, idx) => {
                            return (
                                <p
                                    className={`${
                                        value === obj
                                            ? 'text-[#085BEB] font-[500] border-b-[3px] border-[#085BEB]'
                                            : 'text-[#000000] font-[400]'
                                    } text-[16px] cursor-pointer transition-all ease-in-out duration-300 h-8`}
                                    onClick={() => handleTabChange(obj)}
                                >
                                    {obj}
                                </p>
                            );
                        })}
                    </div>
                    <hr className="border-b-2 -mt-0.5"></hr>
                </div>
                <div className="mt-10 pb-10">
                    {value === 'UpcomingTraining' ? (
                        <UpcomingTraining formik={formik} />
                    ) : (
                        <ReminderSubSection
                            value={value}
                            formik={formik}
                            userValue={userValue}
                            templateValue={templateValue}
                            tabChange={tabChange}
                            plainTextContent={plainTextContent}
                        />
                    )}
                </div>
            </form>
        </div>
    );
};
