import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../..';
import { showAdvertisementModal } from '../../Redux/Reducers/popupReducers';
import {
    setAdvertisement,
    setAdvertisementByIndex,
} from '../../Redux/Reducers/advertisementReducers';

import {
    readRecord,
    postRecord,
    updateRecord,
    deleteRecord,
} from '../../Services/backend/apiCalls';

import { toast } from 'react-hot-toast';
import { dateFormat } from '../Elements/DateFormat';
import { PopupForAdvertisement } from './PopupForAdvertisement';
import { useFormik } from 'formik';
import { postAdvertisementError } from '../../Services/Formik/Formik';
import { ModalBox } from '../UI_Modal/UI_Modal';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const Advertisements = () => {
    const [saveAd, setSaveAd] = useState([]);
    const [adDetails, setAdDetails] = useState([]);
    const [urlUid, setUrlUid] = useState('');
    const { id }: any = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [
        { token, username, email, uid, role },
        { advertisement },
        { showAdModal },
    ] = useAppSelector(({ signin, getAd, modal }: any) => [
        signin['signinValue'],
        getAd['AdvertisementData'],
        modal,
    ]);
    const [urlIndex, setUrlIndex] = useState<any>();
    const [show, setShow] = useState(false);
    const [selectUserId, setSelectUserId] = useState(null);

    const userRole: any = {
        SA: 'Super Admin',
        A: 'Admin',
    };

    const TableHeader = [
        'S.No',
        'File Name',
        'Uploaded date',
        'Uploaded by',
        'Role',
        'Action',
        '',
    ];

    const GetAdvertisement = async () => {
        localStorage.setItem('searchInstitute', '');
        // const getADs = await getAdminAdvertisement(token);
        const getADs = await readRecord({}, 'admin/advertisements');
        console.log(getADs, 'getADs');
        if (getADs.status) {
            setAdDetails(getADs.data);
            navigate(`/dashboard/advertisements`);
            dispatch(setAdvertisement({ advertisement: getADs.data }));
        } else if (
            getADs.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getADs.message);
        } else {
            toast.error(getADs.message);
        }
    };

    useEffect(() => {
        GetAdvertisement();
    }, []);

    const AddImageModel = () => {
        console.log(advertisement, 'advertisement');

        formik.setValues(
            {
                image: '',
                url: '',
                url_Out: '',
                // expiry_time: '',
                file_name: '',
                url_type: 'inside',
            },
            true
        );
        navigate(`/dashboard/advertisements`);
        dispatch(showAdvertisementModal(true));
    };

    const InstantGetData = (data: any) => {
        dispatch(
            setAdvertisement({
                advertisement: [
                    ...advertisement,
                    {
                        ...data,
                        admin: {
                            email,
                            name: username,
                            //   username,
                            role,
                            user_id: uid,
                        },
                    },
                ],
            })
        );
    };

    const InstantChangeWhileEdit = async (getID: any) => {
        // dispatch(setAdvertisementByIndex({id: getID, data: formik.values}));
        const getADsForEdit = await readRecord({}, 'admin/advertisements');
        if (getADsForEdit.status) {
            setAdDetails(getADsForEdit.data);
            navigate(`/dashboard/advertisements`);
            dispatch(setAdvertisement({ advertisement: getADsForEdit.data }));
        } else if (
            getADsForEdit.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(getADsForEdit.message);
        } else {
            toast.error(getADsForEdit.message);
        }
    };

    const InstantChangeWhileDelete = (getID: any) => {
        const RemoveID = advertisement.filter(
            (idx: any) => idx['id'] !== getID
        );
        dispatch(setAdvertisement({ advertisement: [...RemoveID] }));
    };

    const handleSubmitAdvertisement = async () => {
        if (!values.image.length) {
            return formik.setFieldError('image', 'Please upload Image');
        }
        const { url, url_Out, url_type, ...rest } = values;
        const body = {
            url_type: url_type,
            ...(id && { id: Number(id) }),
            ...(url_type === 'inside' && { url: `${url + '/' + urlUid}` }),
            ...(url_type === 'outside' && { url: `${url_Out}` }),
            ...rest,
        };
        // console.log(body, 'body');
        const postAD = await (!id
            ? postRecord(body, 'admin/advertisements')
            : updateRecord(body, 'admin/advertisements'));
        if (postAD.status) {
            toast.success(postAD.message);
            dispatch(showAdvertisementModal(false));
            id ? InstantChangeWhileEdit(id) : InstantGetData(postAD.data);
            navigate(`/dashboard/advertisements`);
            resetForm();
        } else if (
            postAD.message ===
            camelCaseConvertForErrorMessage('Please sign in to continue')
        ) {
            navigate('/');
            toast.error(postAD.message);
        } else {
            toast.error(postAD.message);
        }
    };

    const input = {
        image: '',
        url: '',
        url_Out: '',
        // expiry_time: '2023-03-08',
        file_name: '',
        url_type: 'inside',
    };

    // console.log(urlIndex === 0, '0', urlIndex === 1, "1");

    const formik = useFormik({
        initialValues: input,
        validationSchema: postAdvertisementError(urlIndex),
        onSubmit: () => {
            handleSubmitAdvertisement();
        },
    });

    const {
        values,
        handleChange,
        handleBlur,
        touched,
        handleSubmit,
        setFieldValue,
        errors,
        resetForm,
    } = formik;

    const checkUrlInsideOrOutside = (index: any) => {
        console.log(index, 'index');
        setUrlIndex(index);
    };

    const editRequestSubmit = async (val: any) => {
        dispatch(showAdvertisementModal(true));
        navigate(`/dashboard/advertisements/${val['id']}`);
    };

    const deleteRequestSubmit = async () => {
        console.log(selectUserId, 'selectUserId');
        const RemoveADs = await deleteRecord(
            { id: selectUserId },
            'admin/advertisements'
        );
        if (RemoveADs.status) {
            InstantChangeWhileDelete(selectUserId);
            toast.success(RemoveADs.message);
        } else {
            toast.error(RemoveADs.message);
        }
    };

    const forUidCallBack = (user_id: any) => {
        setUrlUid(user_id);
    };

    const deleteModal = (flag: any) => {
        setShow(false);
        setSelectUserId(null);
        if (flag === 'yes') {
            deleteRequestSubmit();
        } else {
            setShow(false);
        }
    };

    const openModal = (val: any) => {
        setShow(true);
        setSelectUserId(val);
    };

    return (
        <div className="w-11/12 mx-auto mt-8 overflow-hidden">
            {showAdModal && (
                <PopupForAdvertisement
                    formik={formik}
                    checkFunc={checkUrlInsideOrOutside}
                    forUidCallBack={forUidCallBack}
                />
            )}
            <ModalBox show={show} fun={deleteModal} />
            <h1 className="text-[17px] font-[600]">Advertisement</h1>
            <div className="flex justify-between items-center mt-8">
                <p className="text-[16px] font-[500]">Image List</p>
                <button
                    className="bg-[#267DF8] text-white text-[12px] font-[400] p-2.5 px-6 xl:px-8 rounded-[8px]"
                    onClick={AddImageModel}
                >
                    Add
                </button>
            </div>
            <div className="mt-4 w-full pb-10">
                <table className="w-full">
                    <thead>
                        <tr className="flex w-full py-4 border-[#E8ECEF] border-[1px] rounded-t-xl bg-[#F9FAFC]">
                            {TableHeader.map((head, index) => {
                                return (
                                    <th
                                        key={index}
                                        className={`${
                                            index === 0 && 'pl-2'
                                        } text-start w-2/12 text-[#334051] text-[13px] font-[400]`}
                                    >
                                        {head}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {advertisement && advertisement.length === 0 ? (
                        <div className="min-h-[150px] flex items-center justify-center">
                            <p className="text-[#085BEB] font-[500] text-[18px]">
                                No Advertisement
                            </p>
                        </div>
                    ) : (
                        <tbody>
                            {advertisement &&
                                advertisement.map((val: any, key: any) => {
                                    return (
                                        <tr
                                            key={key}
                                            className="flex w-full border-[1px] border-[#E8ECEF] py-4"
                                        >
                                            <td className="pl-2 w-2/12 text-[#11182A] text-[13px] font-[500]">
                                                {key + 1}
                                            </td>
                                            <td className="w-2/12 text-[#11182A] text-[13px] font-[500]">
                                                {val['file_name']}
                                            </td>
                                            <td className="w-2/12 text-[#6B6B6B] text-[13px] font-[400]">
                                                {dateFormat(val['createdAt'])}
                                            </td>
                                            <td className="w-2/12 text-[#11182A] text-[13px] font-[500]">
                                                {username}
                                            </td>
                                            <td className="w-2/12 flex">
                                                <p className="text-[#267DF8] text-[9px] font-[500] text-center bg-[#F4F7FF] p-1 rounded-[10.5px]">
                                                    {
                                                        userRole[
                                                            val['admin']['role']
                                                        ]
                                                    }
                                                </p>
                                            </td>
                                            <td className="w-4/12 flex gap-16">
                                                <td
                                                    className="text-[#267DF8] text-[13px] font-[400] cursor-pointer"
                                                    onClick={() =>
                                                        editRequestSubmit(val)
                                                    }
                                                >
                                                    Edit
                                                </td>
                                                <td
                                                    className="text-[#FF6963] text-[13px] font-[400] cursor-pointer"
                                                    onClick={() =>
                                                        openModal(val['id'])
                                                    }
                                                >
                                                    Delete
                                                </td>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
};
