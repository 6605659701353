import React, {useState} from 'react';
import openEye from "../../../assets/Images/openEye.svg";
import closeEye from "../../../assets/Images/closeEye.svg";

export const Input = ({paddingForEye,role,margin,label,labeldesign,size,onChange,error,value,type,isTouched,design,passwordreset,impo,disabled, ...rest}:any) => {
  const [passwordType, setPasswordType] = useState(false)
  const errorState = isTouched && error;

  return (
      <div
          className={`${design} flex flex-col justify-between ${
              errorState && margin && 'mb-4'
          }`}
      >
          <label className={`${labeldesign ? labeldesign : 'text-lg'}`}>
              {label}
              {impo ? '' : <span className="ml-1">*</span>}
          </label>
          <div
              className={`w-full relative ${
                  errorState && margin && 'mb-4' && role === 'FL'
              }`}
          >
              {label !== 'Content' && (
                  <input
                      disabled={disabled}
                      className={`w-full ${paddingForEye} ${
                          disabled && 'bg-[#CFCFCF] opacity-40'
                      } ${passwordreset} ${
                          labeldesign ? labeldesign : 'text-md'
                      } border border-[#F4F4F4] outline-[#0074FC] p-2 rounded-lg`}
                      type={passwordType ? 'text' : type}
                      value={value}
                      onChange={onChange}
                      {...rest}
                  />
              )}
              {label === 'Content' && (
                  <textarea
                      autoCorrect="false"
                      resize-none
                      disabled={disabled}
                      className={`w-full ${
                          size ? 'h-[60px] p-2' : 'h-[170px] p-4'
                      } mt-4 ${
                          disabled && 'bg-[#CFCFCF] opacity-40'
                      } ${passwordreset} border border-[#F4F4F4] outline-[#0074FC] p-2 rounded-lg`}
                      value={value}
                      onChange={onChange}
                      {...rest}
                  >
                      {value}
                  </textarea>
              )}
              {errorState && (
                  <p
                      className={`text-red-500 text-[12px] w-full text-end mt-1 absolute`}
                  >
                      {error}
                  </p>
              )}
              {type === 'password' ? (
                  passwordType ? (
                      <>
                          <img
                              alt="closeEyeIcon"
                              src={closeEye}
                              className={`w-5 object-contain absolute right-2 top-3 cursor-pointer`}
                              onClick={() => setPasswordType(false)}
                          />
                      </>
                  ) : (
                      <>
                          <img
                              alt="openEyeIcon"
                              src={openEye}
                              className={`w-5 object-contain absolute right-2 top-3 cursor-pointer`}
                              onClick={() => setPasswordType(true)}
                          />
                      </>
                  )
              ) : null}
          </div>
      </div>
  );
}
