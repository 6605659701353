// import { useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { Sections } from './SectionsJSON';

// export const SideNav = () => {
//     const params = useParams();
//     const { dashboardScreen }: any = params;
//     const navigate = useNavigate();
//     // const [tabSelected,setTabSelected]=useState(false)
//     const textType =
//         'text-[14px] lg:text-[17px] xl:text-[18px] font-[400] text-[#808283]';
//     const selectedTextType =
//         'text-[14px] lg:text-[17px] xl:text-[18px] font-[500] text-[#267DF8]';

//     const SelectedDivision = (section: any, index: any) => {
//         navigate(`/dashboard/${section.alt}`);
//     };

//     return (
//         <div className="h-[87vh] w-4/12 lg:w-1/3 xl:w-3/12 space-y-1 mt-6">
//             {Sections.map((section, index) => {
//                 return (
//                     <div
//                         key={index}
//                         className={`${
//                             section.alt.includes(dashboardScreen) ||
//                             (!dashboardScreen &&
//                                 section['alt'] === 'advertisements')
//                                 ? 'flex gap-4 px-2 items-center cursor-pointer p-3 bg-[#F4F7FF] rounded-[6px] ml-4'
//                                 : 'flex gap-4 px-2 items-center cursor-pointer p-3 ml-4'
//                         }`}
//                         onClick={() => SelectedDivision(section, index)}
//                     >
//                         <img
//                             src={
//                                 section.alt.includes(dashboardScreen) ||
//                                 (!dashboardScreen &&
//                                     section['alt'] === 'advertisements')
//                                     ? section.selectedImg
//                                     : section.img
//                             }
//                             alt={section.alt}
//                             className="w-[18px] lg:w-[20px] xl:w-[22px] h-[20px] lg:h-[22px] xl:h-[24px]"
//                         />
//                         <p
//                             className={`${
//                                 section.alt.includes(dashboardScreen)
//                                     ? selectedTextType
//                                     : textType
//                             }`}
//                         >
//                             {section.tab}
//                         </p>
//                     </div>
//                 );
//             })}
//         </div>
//     );
// };

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Sections } from './SectionsJSON';

export const SideNav = () => {
    const params = useParams();
    const { dashboardScreen }: any = params;
    const navigate = useNavigate();
    // const [tabSelected,setTabSelected]=useState(false)
    const textType =
        'text-[14px] lg:text-[17px] xl:text-[18px] font-[400] text-[#808283]';
    const selectedTextType =
        'text-[14px] lg:text-[17px] xl:text-[18px] font-[500] text-[#267DF8]';

    const SelectedDivision = (section: any, index: any) => {
        navigate(`/dashboard/${section.alt}`);
    };

    const currentScreen = dashboardScreen || ''; // Provide a default value

    return (
        <div className="h-[87vh] w-4/12 lg:w-1/3 xl:w-3/12 space-y-1 mt-6">
            {Sections.map((section, index) => {
                return (
                    <div
                        key={index}
                        className={`${
                            section.alt.includes(currentScreen) || // Use currentScreen here
                            (!currentScreen &&
                                section['alt'] === 'advertisements')
                                ? 'flex gap-4 px-2 items-center cursor-pointer p-3 bg-[#F4F7FF] rounded-[6px] ml-4'
                                : 'flex gap-4 px-2 items-center cursor-pointer p-3 ml-4'
                        }`}
                        onClick={() => SelectedDivision(section, index)}
                    >
                        <img
                            src={
                                section.alt.includes(currentScreen) || // Use currentScreen here
                                (!currentScreen &&
                                    section['alt'] === 'advertisements')
                                    ? section.selectedImg
                                    : section.img
                            }
                            alt={section.alt}
                            className="w-[18px] lg:w-[20px] xl:w-[22px] h-[20px] lg:h-[22px] xl:h-[24px]"
                        />

                        <p
                            className={`${
                                section.alt.includes(currentScreen) // Use currentScreen here
                                    ? selectedTextType
                                    : textType
                            }`}
                        >
                            {section.tab}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};
