import { PayloadAction , createSlice } from "@reduxjs/toolkit";
import { signinTypes, signinState } from "../Types/signinUserType";

const initialState: signinState = {
    signinValue: {
        token: localStorage.getItem("token") || "",
        email: localStorage.getItem("email") || "",
        uid: localStorage.getItem("uid") || "",
        role: localStorage.getItem("role") || "",
        place_id: localStorage.getItem("place_id") || "",
        username: localStorage.getItem("username") || "",
        isTrainer:["TI","FL"].includes(localStorage.getItem("role") ||"")
    }
}

const signInSlice = createSlice({
    name: "signin",
    initialState,
    reducers: {
        setSignValue: (state: signinState, action: PayloadAction<any>) => {
            action.payload.token && localStorage.setItem("token", action.payload.token)
            action.payload.email && localStorage.setItem("email", action.payload.email)
            action.payload.uid && localStorage.setItem("uid", action.payload.uid)
            action.payload.role && localStorage.setItem("role", action.payload.role)
            action.payload.place_id && localStorage.setItem("place_id", action.payload.place_id)
            action.payload.username && localStorage.setItem("username", action.payload.username)
            state.signinValue = {...state.signinValue, ...action.payload,isTrainer:["TI","FL"].includes(action.payload.role)};
        }
    }
})

export const { setSignValue } = signInSlice.actions;
export default signInSlice.reducer;