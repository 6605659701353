import React from "react";
import * as Yup from "yup";

var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validateEmail = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

export const CreateValidationSchema = Yup.object().shape({});

export const CreateValidationUpdatePassSchema = Yup.object().shape({
    new_password: Yup.string().required('Enter the new password').matches(strongRegex, "Password must be strong"),
});

export const CreateValidationEmailSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Required').matches(validateEmail, "Only alphabets are allowed for this field ").max(50),
    redirect_url: Yup.string().required("Required"),
});

export const CreateValidationSignSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Required').matches(validateEmail, "Only alphabets are allowed for this field ").max(50),
    password: Yup.string().required('Enter the password'),
});

export const profileForST = Yup.object().shape({
    name: Yup.string().required("Please enter the name"),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
    state: Yup.string(),
})

export const profileForCO = Yup.object().shape({
    name: Yup.string(),
    company_url: Yup.string(),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
    state: Yup.string(),
    country: Yup.string().required("Select anyone country")
})

export const profileForFL = Yup.object().shape({
    name: Yup.string().required("Required"),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
    state: Yup.string(),
    country: Yup.string().required("Select anyone country"),
    linkedin_url: Yup.string(),
    industry: Yup.string().required("Fill the organization name"),
    resume_url: Yup.string().required("Please upload your resume here"),
})

export const profileForTI = Yup.object().shape({
    name: Yup.string().required("Enter your institute name"),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    // achievements: Yup.string().required(""),
    industry: Yup.string().required(" name"),
    url: Yup.string().matches(URL, 'Enter a valid url'),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
})

export const CO = Yup.object().shape({
    organization_name: Yup.string().required('Fill organization name'),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
    company_url: Yup.string().required("Required"),
})

export const ST = Yup.object().shape({
    first_name: Yup.string().max(20, 'Too Long!').required('Enter the firstname'),
    last_name: Yup.string().max(20, 'Too Long!').required('Enter the lastname'),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
})

export const FL = Yup.object().shape({
    first_name: Yup.string().max(20, 'Too Long!').required('Enter the firstname'),
    last_name: Yup.string().max(20, 'Too Long!').required('Enter the lastname'),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
    linkedin_url: Yup.string(),
    industry: Yup.string().required("Enter the industry type"),
    resume_url: Yup.string().required("Please upload your resume here"),
})

export const TI = Yup.object().shape({
    name: Yup.string().required("Enter your institute name"),
    contact_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(10, "Please enter a valid mobile number").required('Required'),
    achievements: Yup.string().required(""),
    industry: Yup.string().required("Fill the organization name"),
    url: Yup.string().matches(URL, 'Enter a valid url'),
    city: Yup.string().required("Select anyone city"),
    address_line: Yup.string().required("Required"),
})

export const addCourses = Yup.object().shape({
    title: Yup.string().required("Please enter the title"),
    description: Yup.string().required("Enter the description"),
    duration_count: Yup.number().required("Enter course duration").positive('Enter a valid percentage').min(1),
    duration_type: Yup.string(),
    fee: Yup.number().required('Enter the Fee').positive('Enter a valid amount').min(1, "Enter valid amount").integer('invalid fee'),
    discount_percentage: Yup.number().required("Enter the discount percentage").max(100, 'invalid percentage').positive('Enter a valid percentage').min(1).integer('invalid percentage'),
    highlights: Yup.string(),
    image: Yup.string().required("Please upload Image"),
    session: Yup.array().min(1,"choose atleast one day").of(Yup.string()).required("session is required"),
    batch_timings: Yup.array().min(1,"atleast one batch timing is required").of(Yup.string()).required("batch timing is required")
})

export const customizeCourses = Yup.object().shape({
    title: Yup.string().required("Please enter the title"),
    description: Yup.string().required("Enter the description"),
    duration_count: Yup.number().required("Enter course duration").positive('Enter a valid percentage').min(1),
    duration_type: Yup.string(),
    fee: Yup.number().required('Enter the Fee').positive('Enter a valid amount').min(1, "Enter valid amount").integer('invalid fee'),
    discount_percentage: Yup.number().required("Enter the discount percentage").max(100, 'invalid percentage').positive('Enter a valid percentage').min(1).integer('invalid percentage'),
    highlights: Yup.string(),
    image: Yup.string().required("Please upload Image"),
    date_of_training: Yup.string().required("Select Date of Joining"),
    session: Yup.array().min(1,"choose atleast one day").of(Yup.string()).required("session is required"),
    batch_timings: Yup.array().min(1,"Minimum one batch timing is required").of(Yup.string()).required("batch timing is required")
})

export const BookNow= Yup.object().shape({
    date_of_training: Yup.string().min(5,"Select Date of Joining").required("Select Date of Joining"),
    batch_timing: Yup.string().min(5,"Select batch Training").required("Select batch Training")
})

export const postAdvertisementError = (urlIndex:any) => {
    return Yup.object().shape({
        // image: Yup.string().required('Please upload Image'),
        ...(urlIndex===0 && {url: Yup.string().required('Enter the Profile Url')}),
        ...(urlIndex===1 && {url_Out: Yup.string().required('Enter the Profile Url')}),
        // .matches(URL, 'Enter a valid url'),
        file_name: Yup.string(),
        expiry_time: Yup.string(),
        url_type: Yup.string(),
    });
};

export const postCouponsError = (text:any) => {
    return Yup.object().shape({
        code: Yup.string().required('Enter Coupon Code'),
        title: Yup.string().required('Enter the Title'),
        description: Yup.string().required('Enter the Description'),
        start_date: Yup.string().required('Enter Start Date'),
        end_date: Yup.string().required('Enter End Date'),
        ...(text === 'Amount'
            ? {
                  amount: Yup.number()
                      .required('Enter amount')
                      .min(1, 'Enter amount')
                      .positive('Enter a valid amount')
                      .integer('invalid amount'),
                  maximum_order_amount: Yup.number()
                      .required('Enter max amount')
                      .min(1, 'Enter amount')
                      .positive('Enter a valid amount')
                      .integer('invalid amount'),
              }
            : {
                  discount: Yup.number()
                      .positive('Enter a valid percentage')
                      .integer('invalid percentage'),
              }),
        ...(text === 'Discount'
            ? {
                  discount: Yup.number()
                      .required('Enter discount value')
                      .max(100, 'Enter less value')
                      .min(1, 'Enter amount')
                      .positive('Enter a valid percentage')
                      .integer('invalid percentage'),
              }
            : {
                  amount: Yup.number()
                      .positive('Enter a valid amount')
                      .integer('invalid amount'),
                  maximum_order_amount: Yup.number()
                      .positive('Enter a valid amount')
                      .integer('invalid amount'),
              }),
    });
}

export const setReminderError = Yup.object().shape({
    data1: Yup.number()
        .required('Enter reminder')
        .positive('Enter a valid days')
        .min(1, 'Enter valid days'),
    data2: Yup.number()
        .required('Enter reminder')
        .positive('Enter a valid days')
        .min(1, 'Enter valid days')
        .test('reminder', 'Enter large value', function (f: any) {
            const ref: any = Yup.ref('data1');
            return f > this.resolve(ref);
        }),
    data3: Yup.number()
        .required('Enter reminder')
        .positive('Enter a valid days')
        .min(1, 'Enter valid days')
        .test('reminder', 'Enter large value', function (f: any) {
            const ref: any = Yup.ref('data2');
            return f > this.resolve(ref);
        }),
});

export const setTaxesError = (text: any) => {
    console.log(text, "text")
    const filterText = text==='percent'?0:1; 
    return Yup.object().shape({
        gst: Yup.number()
            .required('Enter GST Value')
            .positive('Enter a valid percentage')
            .min(1, 'Enter minimum one')
            .max(100, 'Enter valid percentage')
            .integer('invalid percentage'),
        charge_type: Yup.string().required(),
        // internet_charge: Yup.number().when('charge_type', {
        //     is: (filterText: any) => filterText === 0,
        //     then: Yup.number()
        //         .required('Enter Internet Charge Value')
        //         .positive('Enter a valid percentage')
        //         .min(1, 'Enter minimum one')
        //         .max(100, 'Enter valid percentage')
        //         .integer('invalid percentage'),
        //     otherwise: Yup.number()
        //         .required('Enter Internet Charge Value')
        //         .positive('Enter a valid percentage')
        //         .min(1, 'Enter minimum one')
        //         .integer('invalid percentage'),
        // }),
        ...(text.includes('percent') && {
            internet_charge: Yup.number()
                .required('Enter Internet Charge Value')
                .positive('Enter a valid percentage')
                .min(1, 'Enter minimum one')
                .max(100, 'Enter valid percentage')
                .integer('invalid percentage'),
        }),
        ...(text.includes('amount') && {
            internet_charge: Yup.number()
                .required('Enter Internet Charge Value')
                .positive('Enter a valid percentage')
                .min(1, 'Enter minimum one')
                .integer('invalid percentage'),
        }),
    });
}

export const setFeesError = Yup.object().shape({
    data11: Yup.number().required(''),
    data12: Yup.number()
        .required('Enter cancellation days')
        .positive('Enter a valid percentage')
        .integer('invalid percentage')
        .min(1, 'Enter minimum one'),
    data21: Yup.number().required(''),
    data22: Yup.number()
        .required('Enter cancellation days')
        .positive('Enter a valid percentage')
        .min(1, 'Enter minimum one')
        .integer('invalid percentage')
        .test('data', 'Enter large value', function (f: any) {
            const ref: any = Yup.ref('data12');
            return f > this.resolve(ref);
        }),
    data31: Yup.number().required(''),
    data32: Yup.number()
        .required('Enter cancellation days')
        .positive('Enter a valid percentage')
        .min(1, 'Enter minimum one')
        .integer('invalid percentage')
        .test('data', 'Enter large value', function (f: any) {
            const ref: any = Yup.ref('data22');
            return f > this.resolve(ref);
        }),
    percent1: Yup.number()
        .required('Enter percentage value')
        .positive('Enter a valid percentage')
        .min(1, 'Enter minimum one')
        .max(100, 'Enter valid percentage')
        .integer('invalid percentage'),
    percent2: Yup.number()
        .required('Enter percentage value')
        .positive('Enter a valid percentage')
        .min(1, 'Enter value')
        .max(100, 'Enter valid percentage')
        .integer('invalid percentage')
        .test('percent', 'Enter Small Value', function (f: any) {
            const ref: any = Yup.ref('percent1');
            return f < this.resolve(ref);
        }),
    percent3: Yup.number()
        .required('Enter percentage value')
        .positive('Enter a valid percentage')
        .min(1, 'Enter minimum one')
        .max(100, 'Enter valid percentage')
        .integer('invalid percentage')
        .test('percent', 'Enter Small Value', function (f: any) {
            const ref: any = Yup.ref('percent2');
            return f < this.resolve(ref);
        }),
});

export const setNotificationSectionError = Yup.object().shape({
    type: Yup.string().required(),
    template: Yup.string().required('Please enter the template').min(5, 'Enter Minimum 5 in Length'),
    user_type: Yup.string().required(),
    notify_type: Yup.string().required()
});

export const setEmailSectionError = Yup.object().shape({
    type: Yup.string().required(),
    template: Yup.string()
        .required('Please enter the template')
        .min(5, 'Enter Minimum 5 in Length'),
    user_type: Yup.string().required(),
    subject: Yup.string().required('Please enter the Subject'),
    notify_type: Yup.string().required(),
});

export const emailTemplateSchema = Yup.object().shape({
    subject: Yup.string().required('Enter subject'),
    content: Yup.string().required('Enter Content'),
});

export const notifySchema = Yup.object().shape({
    content:Yup.string().required("Enter Content")
})